import "../styles/mp-styles.css"
import styles from "../devlink/ButtonPrimary.module.css";
import { PageLayout } from "../devlink/PageLayout";
import done from "../assets/completed.png"


export const ThankYou = () => {

    return (
        <>
        <PageLayout 
            contentSlot={[
                <div className="thank-container">
                    <img src={done} className="done-icon" alt='success' />
                    <div className="thank-text-block">
                        <div className="heading-style-h5">Thank You</div>
                        <div className="text-color-weak">We'll notify you in 24-48 hours by email or phone when we are ready or if there are additional questions. Have questions? Call us at 
                            <a href="tel:18003293375">
                                <u> +1 800 329 3375</u>
                            </a> 
                        </div>
                    </div>
                </div>
            ]}
        />
        </>
        )
  };