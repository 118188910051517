import { ProductCard } from "../devlink/ProductCard.js"
import "../styles/paired.css"

export const PairedWith = () => {
    
  const results = [
    {
        prodName: "3 Ton Excavator",
        prodBrand: "Sunward America",
        monthlyPrice: "$12000/mo",
        prodImg: "https://uploads-ssl.webflow.com/649e7d984e2a9b5b23e56bf2/649e7d9b4e2a9b5b23e56e07_image.svg",
        fullPrice: "$150,000",
        type: "SWE25UF",
        specLabel1 : "Weight",
        specValue1 : "Value",
        specLabel2 : "Engine Power",
        specValue2 : "Value",
        specLabel3 : "Dig Depth",
        specValue3 : "Value",
    },
    {
      prodName: "3 Ton Excavator",
      prodBrand: "Sunward America",
      monthlyPrice: "$12000/mo",
      prodImg: "https://uploads-ssl.webflow.com/649e7d984e2a9b5b23e56bf2/649e7d9b4e2a9b5b23e56e07_image.svg",
      fullPrice: "$150,000",
      type: "SWE25UF",
    },
    {
      prodName: "3 Ton Excavator",
      prodBrand: "Sunward America",
      monthlyPrice: "$12000/mo",
      prodImg: "https://uploads-ssl.webflow.com/649e7d984e2a9b5b23e56bf2/649e7d9b4e2a9b5b23e56e07_image.svg",
      fullPrice: "$150,000",
      type: "SWE25UF",
    },
]
  
    return (
        <>
            <div className="paired-wrapper">
                <h1 style={{marginBottom: '32px'}} className="heading-style-h3">
                    Commonly paired with
                </h1>
                <div className="paired-grid">
                {results.map((result, index) => (
                    <ProductCard
                        key={index} 
                        result={result}
                        productName={result.prodName}
                        brand={result.prodBrand}
                        monthlyPrice={result.monthlyPrice}
                        fullPrice={result.fullPrice}
                        productImage={result.prodImg}
                        type={result.type}
                        specLabel1={result.specLabel1}
                        specLabel2={result.specLabel2}
                        specLabel3={result.specLabel3}
                    />
                ))}
                </div>
            </div>
        </>
        )
  };