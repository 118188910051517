import axios from 'axios';

const API_KEY = 'keynC8CtcQEvk1kTh';
const API_URL = `https://api.airtable.com/v0/apptUHQFldvXoZtMd/Ecom%20Reservations`;

export const pushDataToAirtable = async (machineDetails, splitterModel, cost, id, fullName, customerId, shippingAddress, phoneNr) => {
  const headers = {
      Authorization: `Bearer ${API_KEY}`,
      'Content-Type': 'application/json'
  };
  
  const data = {
        fields: {
        "Attachment Specifications": machineDetails,
        "Equipment": splitterModel,
        "Transaction Amount (Reservation Total)": cost,
        "Sale Type": "Ecom",
        "Date": new Date().toISOString(),
        "Order/Receipt ID": id,
        "Full Name (Order)": fullName,
        "Customer": [customerId],
        "Billing Address (Order)": shippingAddress,
        "Shipping Address (Order)": shippingAddress,
        "Phone Number (Order)": phoneNr
        }
  };
  console.log("Data being sent to Airtable:", data);
  try {
      const response = await axios.post(API_URL, JSON.stringify(data), {headers});
      console.log("Response:", response.data);
      return response.data.id;
  } catch (error) {
      console.error('Error pushing data to Airtable:', error);
  }
}


export const checkIfCustomerExists = async (email, fullName, machineId) => {
  const CUSTOMERS_URL = `https://api.airtable.com/v0/apptUHQFldvXoZtMd/Customer?filterByFormula=SEARCH("${email}", %7BEmail%7D)`;
  const headers = {
      Authorization: `Bearer ${API_KEY}`,
      'Content-Type': 'application/json'
  };

  try {
      const response = await axios.get(CUSTOMERS_URL, {headers});
      
      if (response.data.records && response.data.records.length > 0) {
          return response.data.records[0].id;
      } else {
        console.log('No records found for the given email:', email, ' Adding new customer..');
        // Add a new customer if no records are found
        await addCustomerToAirtable(email, fullName, machineId);  
        const newResponse = await axios.get(CUSTOMERS_URL, { headers });
        if (newResponse.data.records && newResponse.data.records.length > 0) {
          return newResponse.data.records[0].id;
        }
        throw new Error("Failed to add and retrieve new customer from Airtable");
      }
      
  } catch (error) {
      console.error('Couldnt find customer in Airtable:', error);
      return false; 
  }
}

export const addCustomerToAirtable = async (email, fullName, machineId) => {
    const CUSTOMERS_URL = `https://api.airtable.com/v0/apptUHQFldvXoZtMd/Customer`;
    const headers = {
        Authorization: `Bearer ${API_KEY}`,
        'Content-Type': 'application/json'
    };

    const customerData = {
        fields: {
            "Business Name": `${fullName}'s Company`,
            "Email": email,
            "Main Contact": fullName,
            "Equipment (Fleet)": machineId,
        }
    };

    try {
        const response = await axios.post(CUSTOMERS_URL, customerData, {headers});
        console.log("Added Customer:", response.data);
    } catch (error) {
        console.error('Error adding customer to Airtable:', error);
    }
}

export const getModelFromAirtable = async (model) => {
    const MODELS_URL = `https://api.airtable.com/v0/apptUHQFldvXoZtMd/Models?filterByFormula=SEARCH("${model}", %7BProduct Name%7D)`;
    const headers = {
      Authorization: `Bearer ${API_KEY}`,
      'Content-Type': 'application/json'
    };
  
    try {
      const response = await axios.get(MODELS_URL, {headers});
      if (response.data.records.length > 0) {
        return response.data.records[0].fields['Product Name']; 
      }
      return null; 
    } catch (error) {
      console.error('Couldnt fetch model from Airtable:', error);
      return null;
    }
  }

export const updateMachineDetailsInAirtable = async (orderReceiptId, newMachineDetails) => {
    const headers = {
      Authorization: `Bearer ${API_KEY}`,
      'Content-Type': 'application/json'
    };
  
    // Find the record with the matching "Order/Receipt ID" value
    const response = await axios.get(API_URL, {
      headers,
      params: {
        filterByFormula: `{Order/Receipt ID} = '${orderReceiptId}'`
      }
    });
  
    if (response.data.records.length === 0) {
      console.error('No records found with the provided Order/Receipt ID.');
      return;
    }
  
    const airtableRecordId = response.data.records[0].id;
  
    const data = {
      fields: {
        "Attachment Specifications": newMachineDetails
      }
    };
  
    const url = `${API_URL}/${airtableRecordId}`;
    try {
      const updateResponse = await axios.patch(url, JSON.stringify(data), { headers });
      console.log("Response:", updateResponse.data);
      return updateResponse.data;
    } catch (error) {
      console.error('Error updating data in Airtable:', error);
      throw error;
    }
};

export const getFullNameAndEmailFromAirtable = async (atId) => {
  const headers = {
    Authorization: `Bearer ${API_KEY}`,
    'Content-Type': 'application/json',
  };

  const response = await axios.get(API_URL, {
    headers,
    params: {
      filterByFormula: `{Order/Receipt ID} = '${atId}'`
    }
  });

  if (response.data.records.length === 0) {
    console.error('No records found with the provided Order/Receipt ID.');
    return;
  }

  const newId = response.data.records[0].id;

  const url = `${API_URL}/${newId}`;
  console.log("url", url)

  try {
    const response = await axios.get(url, { headers });

    if (response.data.fields) {
      const fullName = response.data.fields['Full Name (Order)'];
      const email = response.data.fields['Email (from Customer)'];

      return { fullName, email };
    } else {
      throw new Error('Fields not found in Airtable record');
    }
  } catch (error) {
    console.error('Error fetching data from Airtable:', error);
    throw error;
  }
};
