import React from "react";
import * as _Builtin from "./_Builtin";
import * as _utils from "./utils";
import _styles from "./SimplifiedHeader.module.css";

export function SimplifiedHeader({
  as: _Component = _Builtin.Block,
  headerProps = {},
  headerTitle = "Attachment Finder",
  showNavButton = true,
}) {
  return (
    <_Component
      className={_utils.cx(_styles, "section_simplified-header")}
      tag="header"
      {...headerProps}
    >
      <_Builtin.Block
        className={_utils.cx(_styles, "simplified-header")}
        tag="div"
      >
        <_Builtin.Block
          className={_utils.cx(_styles, "padding-global")}
          tag="div"
        >
          <_Builtin.Block
            className={_utils.cx(_styles, "simplified-header_wrapper")}
            tag="div"
          >
            <_Builtin.Block
              className={_utils.cx(_styles, "header-logo-wrapper")}
              tag="div"
            >
              <_Builtin.Block
                className={_utils.cx(_styles, "header-logo_desktop")}
                tag="div"
              >
                <_Builtin.Link
                  button={false}
                  options={{
                    href: "https://www.machinerypartner.com/",
                  }}
                >
                  <_Builtin.Image
                    className={_utils.cx(_styles, "header-logo")}
                    loading="lazy"
                    width="auto"
                    height="auto"
                    alt="__wf_reserved_inherit"
                    src="https://uploads-ssl.webflow.com/649e7d984e2a9b5b23e56bf2/64b537d2291e2933bf27f3c6_mp-logo-mobile.svg"
                  />
                </_Builtin.Link>
                <_Builtin.Block
                  className={_utils.cx(
                    _styles,
                    "divider-vertical",
                    "hide-mobile-portrait"
                  )}
                  tag="div"
                />
                <_Builtin.Block
                  className={_utils.cx(_styles, "header-title")}
                  tag="div"
                >
                  {headerTitle}
                </_Builtin.Block>
              </_Builtin.Block>
              <_Builtin.Block
                className={_utils.cx(_styles, "header-logo_mobile")}
                tag="div"
              >
                <_Builtin.Link
                  button={false}
                  options={{
                    href: "https://www.machinerypartner.com/",
                  }}
                >
                  <_Builtin.Image
                    className={_utils.cx(_styles, "header-logo")}
                    loading="lazy"
                    width="auto"
                    height="auto"
                    alt="__wf_reserved_inherit"
                    src="https://uploads-ssl.webflow.com/649e7d984e2a9b5b23e56bf2/64b537d2291e2933bf27f3c6_mp-logo-mobile.svg"
                  />
                </_Builtin.Link>
              </_Builtin.Block>
            </_Builtin.Block>
            {showNavButton ? (
              <_Builtin.Link
                className={_utils.cx(_styles, "simplified-header_help-button")}
                button={false}
                options={{
                  href: "https://www.machinerypartner.com/contact",
                  target: "_blank",
                }}
              >
                <_Builtin.HtmlEmbed
                  className={_utils.cx(_styles, "icon-1x1-default")}
                  value="%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M21.9999%2016.9201V19.9201C22.0011%2020.1986%2021.944%2020.4743%2021.8324%2020.7294C21.7209%2020.9846%2021.5572%2021.2137%2021.352%2021.402C21.1468%2021.5902%2020.9045%2021.7336%2020.6407%2021.8228C20.3769%2021.912%2020.0973%2021.9452%2019.8199%2021.9201C16.7428%2021.5857%2013.7869%2020.5342%2011.1899%2018.8501C8.77376%2017.3148%206.72527%2015.2663%205.18993%2012.8501C3.49991%2010.2413%202.44818%207.27109%202.11993%204.1801C2.09494%203.90356%202.12781%203.62486%202.21643%203.36172C2.30506%203.09859%202.4475%202.85679%202.6347%202.65172C2.82189%202.44665%203.04974%202.28281%203.30372%202.17062C3.55771%202.05843%203.83227%202.00036%204.10993%202.0001H7.10993C7.59524%201.99532%208.06572%202.16718%208.43369%202.48363C8.80166%202.80008%209.04201%203.23954%209.10993%203.7201C9.23656%204.68016%209.47138%205.62282%209.80993%206.5301C9.94448%206.88802%209.9736%207.27701%209.89384%207.65098C9.81408%208.02494%209.6288%208.36821%209.35993%208.6401L8.08993%209.9101C9.51349%2012.4136%2011.5864%2014.4865%2014.0899%2015.9101L15.3599%2014.6401C15.6318%2014.3712%2015.9751%2014.1859%2016.3491%2014.1062C16.723%2014.0264%2017.112%2014.0556%2017.4699%2014.1901C18.3772%2014.5286%2019.3199%2014.7635%2020.2799%2014.8901C20.7657%2014.9586%2021.2093%2015.2033%2021.5265%2015.5776C21.8436%2015.9519%2022.0121%2016.4297%2021.9999%2016.9201Z%22%20stroke%3D%22%23727373%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3C%2Fsvg%3E"
                />
                <_Builtin.Block tag="div">{"Talk to an Expert"}</_Builtin.Block>
              </_Builtin.Link>
            ) : null}
          </_Builtin.Block>
        </_Builtin.Block>
      </_Builtin.Block>
    </_Component>
  );
}
