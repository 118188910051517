import React from "react";
import * as _Builtin from "./_Builtin";
import * as _utils from "./utils";
import _styles from "./ButtonDisabled.module.css";

export function ButtonDisabled({
  as: _Component = _Builtin.Link,
  label = "Label",

  link = {
    href: "#",
  },

  id,
  buttonProps = {},
}) {
  return (
    <_Component
      className={_utils.cx(_styles, "button", "is-disabled")}
      button={true}
      id={id}
      options={link}
      {...buttonProps}
    >
      {label}
    </_Component>
  );
}
