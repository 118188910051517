import React, { useEffect } from "react";
import { FilterCardDefault } from "../devlink/FilterCardDefault";
import { FilterCardisSelected } from "../devlink/FilterCardisSelected";
import { ButtonSecondary } from "../devlink/ButtonSecondary";
import { ButtonPrimary } from "../devlink/ButtonPrimary";
import { ButtonDisabled} from "../devlink/ButtonDisabled";
import { useNavigate } from "react-router-dom";
import excavator from "../assets/excavator-medium.png";
import steer from "../assets/skid-steer-medium.png";
import styles from "../devlink/ButtonPrimary.module.css";
import alternate from "../devlink/ButtonSecondary.module.css";
import { useAtom } from 'jotai';
import { excavatorAtom, skidAtom} from '../components/atoms';
import { PageLayout } from "../devlink/PageLayout";
import { FormContainer } from "../devlink/FormContainer";
import mixpanel from "mixpanel-browser";

mixpanel.init("93036cc0bfa1ceab46ce5584c709ac7f");

export function FirstStep() {

    const navigate = useNavigate()
    const [excavatorSelected, setExcavatorSelected] = useAtom(excavatorAtom);
    const [skidSelected, setSkidSelected] = useAtom(skidAtom);

    const handleClick = () => {
        setExcavatorSelected(!excavatorSelected)
        setSkidSelected(false)
    };
    const handleClick2 = () => {
        setSkidSelected(!skidSelected)
        setExcavatorSelected(false)
    };

    const handleNavigate = () => {
        if (skidSelected) {
            mixpanel.track("Selected Skid Steer");
            navigate(`/2`);
          } else {
            mixpanel.track("Selected Excavator");
            navigate(`/2a`);
          }
    }

    const handleBackButton = () => {
        navigate(`/`);
    }

    useEffect(() => {
        const beforeUnloadHandler = () => {
          mixpanel.track("Abandoned First Step");
        };
        
        window.addEventListener("beforeunload", beforeUnloadHandler);
    
        return () => {
          window.removeEventListener("beforeunload", beforeUnloadHandler);
        };
      }, []);
    
    return (
        <>
        <PageLayout 
            contentSlot={[
                <FormContainer 
                    question={"What machine is this attachment for?"}
                    step={"1 / 5"}
                    showDescription={false}
                    formSlot={[
                    excavatorSelected ? (
                        <FilterCardisSelected
                        title={"Excavator"}
                        showImage={true}
                        image={"https://mp-attachments.vercel.app/static/media/excavator-medium.9546d7c3feb391fcba0f.png"}
                        showDescription={false}
                        filterCardProps={{ onClick: handleClick }}
                        />
                    ) : (
                        <FilterCardDefault 
                        title={"Excavator"}
                        showImage={true}
                        image={"https://mp-attachments.vercel.app/static/media/excavator-medium.9546d7c3feb391fcba0f.png"}
                        showDescription={false}
                        filterCardProps={{onClick: handleClick}}
                        />
                    ),
                    skidSelected ? (
                        <FilterCardisSelected 
                        title={"Skid Steer"}
                        showImage={true}
                        image={"https://mp-attachments.vercel.app/static/media/skid-steer-medium.923d64988f732209b6aa.png"}
                        showDescription={false}
                        filterCardProps={{ onClick: handleClick2 }}
                        />
                    ) : (
                        <FilterCardDefault 
                        title={"Skid Steer"}
                        showImage={true}
                        image={"https://mp-attachments.vercel.app/static/media/skid-steer-medium.923d64988f732209b6aa.png"}
                        showDescription={false}
                        filterCardProps={{ onClick: handleClick2 }}
                        />
                    )
                ]}
                buttonWrapper={[
                    excavatorSelected || skidSelected ? (
                        <button className={styles.button} onClick={handleNavigate}>Next</button>
                    ) : (
                    <ButtonDisabled 
                        label={"Next"}
                    />),
                    
                ]}
            />
            ]}
            />
        </>
    );
}