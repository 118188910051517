import React from "react";
import * as _Builtin from "./_Builtin";
import { GlobalStyles } from "./GlobalStyles";
import { SimplifiedHeader } from "./SimplifiedHeader";
import { SimplifiedFooter } from "./SimplifiedFooter";
import * as _utils from "./utils";
import _styles from "./PageLayout.module.css";

export function PageLayout({ as: _Component = _Builtin.Block, contentSlot }) {
  return (
    <_Component className={_utils.cx(_styles, "page-wrapper")} tag="div">
      <GlobalStyles />
      <_Builtin.Block
        className={_utils.cx(_styles, "main-wrapper", "is-flex")}
        tag="main"
      >
        <SimplifiedHeader />
        <_Builtin.Block
          className={_utils.cx(
            _styles,
            "section_filter",
            "background-color-default"
          )}
          tag="section"
        >
          <_Builtin.Block
            className={_utils.cx(_styles, "padding-global")}
            tag="div"
          >
            <_Builtin.Block
              className={_utils.cx(_styles, "page-container")}
              tag="div"
            >
              {contentSlot}
            </_Builtin.Block>
          </_Builtin.Block>
        </_Builtin.Block>
        <SimplifiedFooter />
      </_Builtin.Block>
    </_Component>
  );
}
