import React from "react";
import InputField from "./inputField";
import SelectField from "./selectField";

const Field = (props) => {
  switch (props.type) {
    case "TEXT":
      return <InputField {...props} />;
    case "SELECT":
      return <SelectField {...props} />;
    default:
      throw new Error("Invalid Field");
  }
};

export default Field;