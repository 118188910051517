import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import "../../styles/mp-styles.css"

const InputField = ({
    label,
    name,
    type,
    defaultValue,
    validation,
    placeholder,
    img,
    imgColor,
    letter,
    letterColor
  }) => {
    const {
      register,
      formState: { errors },
    } = useFormContext();
  
    const outerLayerStyle = {
      display: 'flex',
      width: '24px',
      height: '24px',
      paddingBottom: '0px',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '563.698px',
      background: imgColor,
    };
  
    const letterStyle = {
      fontSize: '16px',
      fontWeight: 'bold',
      color: letterColor,
    };

    return (
      <div className="input-container">
        <label htmlFor={name} {...(validation.required ? { 'data-required': 'true'} : {})} className="input-label">
          {img ? (    
            <div style={outerLayerStyle}>
              <span style={letterStyle}>{letter}</span>
            </div>) 
            : 
            null}
            {label}{/* <span style={{ fontWeight: 'normal' }}>(optional)</span> */}
        </label>
        <input
          className={`input-field ${errors[name]?.type === "required" ? ('border-[#FA5252] bg-[#FFF5F5]') : ('border-neutral-300')}`}
          defaultValue={defaultValue}
          {...register(name, validation)}
          id={name}
          placeholder={placeholder}
          type={type}
        />
        {errors[name] ? (
          <>
            {errors[name]?.type === "required" ? (
              <>
                <span className="input-error">
                  Field "{label}" is required.
                </span>
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
      </div>
    );
  };
  
  export default InputField;
  