import { ButtonSecondary } from "../devlink/ButtonSecondary";
import { ButtonGhost } from "../devlink/ButtonGhost";
import { ResultCard } from "../devlink/ResultCard";
import '../styles/results.css';
import { excavatorAtom, skidAtom } from './atoms';
import { useBestSkid } from "./skidLogic"
import { useBestExcavator } from "./excavatorLogic";
import { useAtom, useSetAtom  } from 'jotai';
import { useEffect, useState } from "react";
import { loadingState } from './atoms';

const slugify = require("slugify");
const baseUrl = "https://www.machinerypartner.com/heavy-equipment/"

const slugifyOptions = {
  replacement: "-", // replace spaces with replacement character, defaults to `-`
  remove: undefined, // remove characters that match regex, defaults to `undefined`
  lower: true, // convert to lower case, defaults to `false`
  strict: true, // strip special characters except replacement, defaults to `false`
  locale: "vi", // language code of the locale to use
  trim: true, // trim leading and trailing replacement chars, defaults to `true`
};

function isDuplicateItem(equipmentData, item) {
  return equipmentData.some(
    (dataItem) =>
      dataItem.productName === item.productName &&
      dataItem.brand === item.brand &&
      dataItem.category === item.category &&
      dataItem.price === item.price &&
      dataItem.buyUrl === item.buyUrl
  );
}

export const ResultOptions = (props) => {

    const [isExcavator] = useAtom(excavatorAtom);
    const [isSkid] = useAtom(skidAtom);
    const bestExcavatorEquipment = useBestExcavator();
    const bestSkidEquipment = useBestSkid();
    const [equipmentData, setEquipmentData] = useState([]);
    const setLoading = useSetAtom (loadingState);

    function formatBestEquipment(equipment) {
      if (Array.isArray(equipment)) {
        return equipment.map(item => formatSingleEquipment(item));
      } else {
        return formatSingleEquipment(equipment);
      }
    }

    function formatSingleEquipment(item) {
      let formattedItem = item.toLowerCase();
      formattedItem = formattedItem.replace(/\b2sg\b/g, '2-speed-grapple');
      formattedItem = formattedItem.replace(/\bssg\b/g, 'single-speed-grapple');
      formattedItem = formattedItem.replace(/\bss\b/g, 'single-speed');
      formattedItem = formattedItem.replace(/\b2s\b/g, '2-speed');
      formattedItem = formattedItem.replace(/\s+/g, '-');
      return formattedItem;
    }

    const getBestEq = async () => {
      let bestEquipment;
      
      if (isExcavator) {
        bestEquipment = bestExcavatorEquipment;
      } else if (isSkid) {
        bestEquipment = bestSkidEquipment;
      } else {
        bestEquipment = [];
      }
    
      console.log("bestEq", bestEquipment);
    
      if (typeof bestEquipment === 'string') {
        if (bestEquipment === "n/a") {
          window.location.href = 'https://www.machinerypartner.com/no-results';
          return;
        } else {
          const response = await fetch(`https://mp-loan-application.vercel.app/api/models/compare-models?names=${bestEquipment}`);
          if (response.ok) {
            const data = await response.json();
            const item = data[0]; // Assuming you're dealing with the first item here
            window.location.href = `https://www.machinerypartner.com/heavy-equipment/${slugify(`${item.manufacturer.name} ${formatSingleEquipment(item.name)} ${item.category.slug.slice(0, -1)}`, slugifyOptions)}`;
            return;
          }
        }
      }
    
      try {
        const response = await fetch(`https://mp-loan-application.vercel.app/api/models/compare-models?names=${bestEquipment}`);
        if (response.ok) {
          const data = await response.json();
    
          const equipmentData = data.map((item) => ({
            productName: item.title,
            brand: item.manufacturer.name,
            category: item.category.name,
            prodImg: item.avatar_file.url,
            buyUrl: slugify(
              `${item.manufacturer.name} ${formatSingleEquipment(item.name)} ${item.category.slug.slice(0, -1)}`,
              slugifyOptions
            ),
            price: parseInt(item.price).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 0,
            }),
          }));
    
          setEquipmentData(equipmentData);
        } else {
          console.error("Error fetching data:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
      
      const handleClick = (e) => {
        console.log('Button clicked');
        e.preventDefault();
        if (props.tableRef.current) {
          props.tableRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }

      useEffect(() => {
          async function fetchData() {
            const data = await getBestEq();
            console.log('Best Equipment data:', data);
            setLoading(false);
          }
          fetchData();
        }, []);

return (
  <>
    <div className="results-modal">
{/*       <h1 style={{ marginBottom: "32px" }} className="heading-style-h3">
        Good news, we found these options that match your needs
      </h1> */}
      <div className="results-wrapper">
        {equipmentData.length === 1 ? ( // If there's only one result, render the single ResultCard
          <ResultCard
            productName={equipmentData[0].productName}
            brand={equipmentData[0].brand}
            category={equipmentData[0].category}
            price={equipmentData[0].price}
            productImage={equipmentData[0].prodImg}
            detailsUrl={{ target:"_blank", href: `https://www.machinerypartner.com/heavy-equipment/${equipmentData[0].buyUrl}` }}
            buyNowUrl={{ target:"_blank", href: `https://www.machinerypartner.com/heavy-equipment/${equipmentData[0].buyUrl}` }}
            buyNowLabel={"Product Details"}
            showSecondaryBtn={false}
            imageLink={{ target:"_blank", href: `https://www.machinerypartner.com/heavy-equipment/${equipmentData[0].buyUrl}` }}
          />
        ) : (
          // If there are more than one result, map and render all ResultCards
          equipmentData.map((item, index) => (
            <ResultCard
              key={index}
              productName={item.productName}
              brand={item.brand}
              category={item.category}
              price={item.price}
              productImage={item.prodImg}
              buyNowUrl={{ target:"_blank", href: `https://www.machinerypartner.com/heavy-equipment/${item.buyUrl}` }}
              detailsUrl ={{ target:"_blank", href: `https://www.machinerypartner.com/heavy-equipment/${item.buyUrl}` }}
              buyNowLabel={"Product Details"}
              showSecondaryBtn={false}
              imageLink={{ target:"_blank", href: `https://www.machinerypartner.com/heavy-equipment/${equipmentData[0].buyUrl}` }}
            />
          ))
        )}
      </div>
{/*       <div
        style={{
          justifySelf: "center",
          marginTop: "47px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <ButtonSecondary
          label={"Compare Models"}
          buttonProps={{ onClick: handleClick }}
        />
        <ButtonGhost label={"Not sure? Talk to an expert!"} link={{href: '#'}} buttonProps={{'fs-modal-element': 'open'}} />
      </div> */}
    </div>
  </>
);
  };