import React, {useEffect, useRef } from 'react';
import { CompareTable } from "../components/compare"
import { PairedWith } from "../components/paired"
import { ResultOptions } from "../components/recommendations"
import { LoadingScreen } from "../components/LoadingScreen";
import "../styles/mp-styles.css";
import { useAtom } from 'jotai';
import { excavatorAtom, highSelectedAtom, largeExAtom, largeSkidAtom, loadingState, longMaterialAtom, longSelectedAtom, mediumExAtom, mediumMaterialAtom, mediumSelectedAtom, mediumSkidAtom, miniExAtom, miniSkidAtom, overExAtom, shortSelectedAtom, skidAtom, smallExAtom, standardSelectedAtom, underExAtom } from '../components/atoms';
import { PageLayout } from '../devlink/PageLayout';
import { ResultPage } from '../devlink/ResultPage';
import mixpanel from "mixpanel-browser";
import { useNavigate } from 'react-router-dom';



export const ResultsPage = () => {

    const navigate = useNavigate();
    mixpanel.init("93036cc0bfa1ceab46ce5584c709ac7f");
    const [isLoading] = useAtom(loadingState);
    const tableRef = useRef();
    const scrollThreshold = 400;

    const [excavator,] = useAtom(excavatorAtom);
    const [miniEx,] = useAtom(miniExAtom);
    const [smallEx,] = useAtom(smallExAtom);
    const [mediumEx,] = useAtom(mediumExAtom);
    const [largeEx,] = useAtom(largeExAtom);
    const [under,] = useAtom(underExAtom);
    const [over,] = useAtom(overExAtom);
    const [mediumMat,] = useAtom(mediumMaterialAtom);
    const [largeMat,] = useAtom(longMaterialAtom);

    const [skidSteer,] = useAtom(skidAtom);
    const [miniSkid,] = useAtom(miniSkidAtom);
    const [mediumSkid,] = useAtom(mediumSkidAtom);
    const [largeSkid,] = useAtom(largeSkidAtom);
    const [standard,] = useAtom(standardSelectedAtom);
    const [high,] = useAtom(highSelectedAtom);
    const [shortMat,] = useAtom(shortSelectedAtom);
    const [medMat,] = useAtom(mediumSelectedAtom);
    const [largeMats,] = useAtom(longSelectedAtom);

    const updateURL = () => {
        let slug = 'results';

        if (excavator) {
            slug += '/Excavator';
        }
        if (miniEx) {
            slug += '+miniEx';
        }
        if (smallEx) {
            slug += '+smallEx';
        }
        if (mediumEx) {
            slug += '+mediumEx';
        }
        if (largeEx) {
            slug += '+largeEx';
        }
        if (under) {
            slug += '+lowPressure';
        }
        if (over) {
            slug += '+highPressure';
        }
        if (largeMat) {
            slug += '+longMaterial';
        }
        if (mediumMat) {
            slug += '+mediumMaterial';
        }
        if (skidSteer) {
            slug += '/SkidSteer';
        }
        if (miniSkid) {
            slug += '+mini';
        }
        if (mediumSkid) {
            slug += '+medium';
        }
        if (largeSkid) {
            slug += '+large';
        }
        if (standard) {
            slug += '+standard';
        }
        if (high) {
            slug += '+high';
        }
        if (shortMat) {
            slug += '+shortMaterial';
        }
        if (medMat) {
            slug += '+mediumMaterial';
        }
        if (largeMats) {
            slug += '+longMaterial';
        }
        console.log("slug", slug)
        navigate(`/${slug}`, { replace: true });
    };
    
    
    useEffect(() => {
        updateURL();
    }, []);

    const handleScroll = () => {
        if (window.scrollY > scrollThreshold) {
            mixpanel.track("Viewed the Comparison Table");
            window.removeEventListener("scroll", handleScroll);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <>
        {isLoading && <PageLayout 
            contentSlot={[
                <LoadingScreen />
            ]}
        />}
        <div style={{ display: isLoading ? 'none' : 'block' }}>
        <ResultPage
            pageTitle={'Results'}
            pageSubtitle={'Good news, we found these options that match your needs'}
            resultSlot={[
                <div id='attachments-results'>
                    {isLoading && <LoadingScreen />}
                    {/* <div style={{ display: isLoading ? 'none' : 'block' }}> */}
                    <ResultOptions tableRef={tableRef} />
                    {/* </div> */}
                </div>
            ]}
            tableSlot={[
                <div id='attachments-results'>
                    <div style={{ display: isLoading ? 'none' : 'block' }}>
                        <CompareTable ref={tableRef} />
                    </div>
                </div>
            ]}
            showPairedWith={false}
            pairedSlot={[
                <div id='attachments-results'>
                {isLoading && <LoadingScreen />}
                <div style={{ display: isLoading ? 'none' : 'block' }}>
                    <PairedWith />
                </div>
                </div>
            ]}
        />
        </div>
        </>
    );
};