import React, { useEffect, useState } from "react";
import { FilterCardDefault } from "../devlink/FilterCardDefault";
import { FilterCardisSelected } from "../devlink/FilterCardisSelected";
import { ButtonSecondary } from "../devlink/ButtonSecondary";
import { ButtonPrimary } from "../devlink/ButtonPrimary";
import { ButtonDisabled} from "../devlink/ButtonDisabled";
import mini from "../assets/excavator-mini.png";
import small from "../assets/excavator-small.png";
import medium from "../assets/excavator-medium.png";
import large from "../assets/excavator-large.png";
import styles  from "../devlink/ButtonPrimary.module.css";
import { useNavigate } from "react-router-dom";
import alternate from "../devlink/ButtonSecondary.module.css";
import { useAtom } from 'jotai';
import { largeExAtom, mediumExAtom, miniExAtom, smallExAtom } from "../components/atoms";
import { FormContainer } from "../devlink/FormContainer";
import { PageLayout } from "../devlink/PageLayout";
import mixpanel from "mixpanel-browser";

mixpanel.init("93036cc0bfa1ceab46ce5584c709ac7f");

export function SecondStepAlt() {

    const navigate = useNavigate();
    const [miniExSelected, setMiniExSelected] = useAtom(miniExAtom);
    const [smallExSelected, setSmallExSelected] = useAtom(smallExAtom);
    const [mediumExSelected, setMediumExSelected] = useAtom(mediumExAtom);
    const [largeExSelected, setLargeExSelected] = useAtom(largeExAtom);

    const handleClick = () => {
        setMiniExSelected(!miniExSelected)
        setMediumExSelected(false)
        setLargeExSelected(false)
        setSmallExSelected(false)
    };
    const handleClick2 = () => {
        setSmallExSelected(!smallExSelected)
        setMiniExSelected(false)
        setLargeExSelected(false)
        setMediumExSelected(false)
    };
    const handleClick3 = () => {
        setMediumExSelected(!mediumExSelected)
        setMiniExSelected(false)
        setSmallExSelected(false)
        setLargeExSelected(false)
    };
    const handleClick4 = () => {
        setLargeExSelected(!largeExSelected)
        setSmallExSelected(false)
        setMiniExSelected(false)
        setMediumExSelected(false)
    };

    const handleNavigate = () => {
        let selectedExcavator = '';
        
        if (miniExSelected) {
            selectedExcavator = 'Mini';
        } else if (smallExSelected) {
            selectedExcavator = 'Small';
        } else if (mediumExSelected) {
            selectedExcavator = 'Medium';
        } else if (largeExSelected) {
            selectedExcavator = 'Large';
        }
        
        mixpanel.track("Selected Excavator Size", {
            'Excavator Size': selectedExcavator
        });
        navigate(`/3a`);
    }

    const handleBackButton = () => {
        navigate(`/`);
    }

    useEffect(() => {
        const beforeUnloadHandler = () => {
          mixpanel.track("Abandoned Excavator Second Step");
        };
        
        window.addEventListener("beforeunload", beforeUnloadHandler);
    
        return () => {
          window.removeEventListener("beforeunload", beforeUnloadHandler);
        };
      }, []);

    return (
        <>
        <PageLayout 
            contentSlot={[
                <FormContainer
                question={"How heavy is your excavator?"}
                step={"2 / 5"}
                showDescription={false}
                formSlot={[
                    miniExSelected ? (
                        <FilterCardisSelected
                          title={"Mini"}
                          showImage={true}
                          image={"https://mp-attachments.vercel.app/static/media/excavator-mini.f99e1b943f3e94865952.png"}
                          showDescription={true}
                          description={"Less than 7 tons"}
                          filterCardProps={{ onClick: handleClick }}
                        />
                      ) : (
                        <FilterCardDefault 
                          title={"Mini"}
                          showImage={true}
                          image={"https://mp-attachments.vercel.app/static/media/excavator-mini.f99e1b943f3e94865952.png"}
                          showDescription={true}
                          description={"Less than 7 tons"}
                          filterCardProps={{ onClick: handleClick }}
                        />
                      ),
                    smallExSelected ? (
                    <FilterCardisSelected 
                            title={"Small"}
                            showImage={true}
                            image={"https://mp-attachments.vercel.app/static/media/excavator-small.07fe7934278fa6d9b8f3.png"}
                            showDescription={true}
                            description={"7 to 10 tons"}
                            filterCardProps={{ onClick: handleClick2 }}
                        />
                        ) : (
                        <FilterCardDefault 
                            title={"Small"}
                            showImage={true}
                            image={"https://mp-attachments.vercel.app/static/media/excavator-small.07fe7934278fa6d9b8f3.png"}
                            showDescription={true}
                            description={"7 to 10 tons"}
                            filterCardProps={{ onClick: handleClick2 }}
                        />
                    ),
                    mediumExSelected ? (
                        <FilterCardisSelected 
                                title={"Medium"}
                                showImage={true}
                                image={"https://mp-attachments.vercel.app/static/media/excavator-medium.9546d7c3feb391fcba0f.png"}
                                showDescription={true}
                                description={"10 to 45 tons"}
                                filterCardProps={{ onClick: handleClick3 }}
                            />
                            ) : (
                            <FilterCardDefault 
                            title={"Medium"}
                            showImage={true}
                            image={"https://mp-attachments.vercel.app/static/media/excavator-medium.9546d7c3feb391fcba0f.png"}
                            showDescription={true}
                            description={"10 to 45 tons"}
                            filterCardProps={{ onClick: handleClick3 }}
                            />
                        ),
                    largeExSelected ? (
                        <FilterCardisSelected 
                                title={"Large"}
                                showImage={true}
                                image={"https://mp-attachments.vercel.app/static/media/excavator-large.03f38914a0e3bd4ee4f3.png"}
                                showDescription={true}
                                description={"Over 45 tons"}
                                filterCardProps={{ onClick: handleClick4 }}
                            />
                            ) : (
                        <FilterCardDefault 
                                title={"Large"}
                                showImage={true}
                                image={"https://mp-attachments.vercel.app/static/media/excavator-large.03f38914a0e3bd4ee4f3.png"}
                                showDescription={true}
                                description={"Over 45 tons"}
                                filterCardProps={{ onClick: handleClick4 }}
                            />
                        )
                    ]}
                    buttonWrapper={[
    /*                     <ButtonSecondary 
                            label={"Back"}   
                            link={{href: "/",}} 
                        />, */
                        <button className={`${alternate.button} ${alternate['is-secondary']}`} onClick={handleBackButton}>Back</button>,
                        miniExSelected || largeExSelected || mediumExSelected || smallExSelected ? (
    /*                         <ButtonPrimary 
                                label={"Next"}
                                link={{href: "/3a",}}
                            /> */
                            <button className={styles.button} onClick={handleNavigate}>Next</button>
                        ) : (
                        <ButtonDisabled 
                            label={"Next"}
                        />)
                    ]}
                />
            ]}
        />
        </>
    );
}