import React, { useEffect, useState } from "react";
import { FilterCardDefault } from "../devlink/FilterCardDefault";
import { FilterCardisSelected } from "../devlink/FilterCardisSelected";
import { ButtonSecondary } from "../devlink/ButtonSecondary";
import { ButtonPrimary } from "../devlink/ButtonPrimary";
import { ButtonDisabled} from "../devlink/ButtonDisabled";
import medium from "../assets/skid-steer-medium.png";
import small from "../assets/skid-steer-standon.png";
import large from "../assets/skid-steer-large.png";
import styles  from "../devlink/ButtonPrimary.module.css";
import { useNavigate } from "react-router-dom";
import alternate from "../devlink/ButtonSecondary.module.css"
import { useAtom } from 'jotai';
import { miniSkidAtom, mediumSkidAtom, largeSkidAtom } from "../components/atoms";
import { FormContainer } from "../devlink/FormContainer";
import { PageLayout } from "../devlink/PageLayout";
import mixpanel from "mixpanel-browser";

mixpanel.init("93036cc0bfa1ceab46ce5584c709ac7f");

export function SecondStep() {

    const navigate = useNavigate();
    const [miniSkid, setMiniSkid] = useAtom(miniSkidAtom);
    const [mediumSkid, setMediumSkid] = useAtom(mediumSkidAtom);
    const [largeSkid, setLargeSkid] = useAtom(largeSkidAtom);

    const handleClick = () => {
        setMiniSkid(!miniSkid);
        setMediumSkid(false);
        setLargeSkid(false);
    };
      
    const handleClick2 = () => {
        setMediumSkid(!mediumSkid);
        setMiniSkid(false);
        setLargeSkid(false);
    };
      
    const handleClick3 = () => {
        setLargeSkid(!largeSkid);
        setMiniSkid(false);
        setMediumSkid(false);
    };
    
    const handleNavigate = () => {
        let selectedSkidSize = '';
        
        if (miniSkid) {
            selectedSkidSize = 'Mini - Stand On';
        } else if (mediumSkid) {
            selectedSkidSize = 'Medium';
        } else if (largeSkid) {
            selectedSkidSize = 'Large';
        }
        
        mixpanel.track("Selected Skid Steer Size", {
            'Skid Size': selectedSkidSize
        });
        
        navigate(`/3`);
    }

    const handleBackButton = () => {
        navigate(`/`);
    }

    useEffect(() => {
        const beforeUnloadHandler = () => {
          mixpanel.track("Abandoned Skid Steer Second Step");
        };
        
        window.addEventListener("beforeunload", beforeUnloadHandler);
    
        return () => {
          window.removeEventListener("beforeunload", beforeUnloadHandler);
        };
      }, []);

    return (
        <>
        <PageLayout 
            contentSlot={[
                <FormContainer
                    question={"How heavy is your skid steer?"}
                    step={"2 / 5"}
                    showDescription={false}
                    formSlot={[
                    miniSkid  ? (
                        <FilterCardisSelected
                            title={"Mini - Stand On"}
                            showImage={true}
                            image={"https://mp-attachments.vercel.app/static/media/skid-steer-standon.7c37bc25e2155309d45c.png"}
                            showDescription={true}
                            description={"Less than 6,000 lbs"}
                            filterCardProps={{ onClick: handleClick }}
                        />
                    ) : (
                        <FilterCardDefault 
                            title={"Mini - Stand On"}
                            showImage={true}
                            image={"https://mp-attachments.vercel.app/static/media/skid-steer-standon.7c37bc25e2155309d45c.png"}
                            showDescription={true}
                            description={"Less than 6,000 lbs"}
                            filterCardProps={{onClick: handleClick}}
                    />
                    ),
                    mediumSkid ? (
                    <FilterCardisSelected 
                            title={"Medium"}
                            showImage={true}
                            image={"https://mp-attachments.vercel.app/static/media/skid-steer-medium.923d64988f732209b6aa.png"}
                            showDescription={true}
                            description={"Between 6,000 - 9,000 lbs"}
                            filterCardProps={{ onClick: handleClick2 }}
                        />
                        ) : (
                        <FilterCardDefault 
                            title={"Medium"}
                            showImage={true}
                            image={"https://mp-attachments.vercel.app/static/media/skid-steer-medium.923d64988f732209b6aa.png"}
                            showDescription={true}
                            description={"Between 6,000 - 9,000 lbs"}
                            filterCardProps={{ onClick: handleClick2 }}
                        />
                    ),
                    largeSkid ? (
                        <FilterCardisSelected 
                                title={"Large"}
                                showImage={true}
                                image={"https://mp-attachments.vercel.app/static/media/skid-steer-large.66be2d87be24a5d44023.png"}
                                showDescription={true}
                                description={"Over 9,000 lbs"}
                                filterCardProps={{ onClick: handleClick3 }}
                            />
                            ) : (
                            <FilterCardDefault 
                            title={"Large"}
                            showImage={true}
                            image={"https://mp-attachments.vercel.app/static/media/skid-steer-large.66be2d87be24a5d44023.png"}
                            showDescription={true}
                            description={"Over 9,000 lbs"}
                            filterCardProps={{ onClick: handleClick3 }}
                            />
                        )
                    ]}
                    buttonWrapper={[
                        <button className={`${alternate.button} ${alternate['is-secondary']}`} onClick={handleBackButton}>Back</button>,
                        miniSkid || largeSkid || mediumSkid ? (
                            <button className={styles.button} onClick={handleNavigate}>Next</button>
                        ) : (
                        <ButtonDisabled 
                            label={"Next"}
                        />)
                    ]}
                />
            ]}
        />
        </>
    );
}