import React from 'react';
import { useTable } from 'react-table';
import '../styles/tabletStyles.css';

export const Table = ({ data }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: '',
        accessor: 'property',
        Cell: ({ value }) => <div className="property-label">{value}</div>,
      },
      ...data.map((item) => ({
        Header: item['Equipment Name'],
        accessor: item['Equipment Name'],
        Cell: ({ value }) => <div className="row-title">{value}</div>,
      })),
    ],
    [data]
  );

  const tableData = React.useMemo(() => {
    const rowData = [
      'Splitter Weight (lbs)',
      'Cone Size (length x base)',
      'Shaft Diameter (inches)',
      'Max Split Size (length in feet)',
      'Oil Quantity (gpm)',
      'Oil Pressure (PSI)',
      'Torque (ft-lbs)',
      'Carrier Weight (lbs)',
      'Product Details',
    ];

    return rowData.map((property) => {
      const row = {
        property,
      };

      data.forEach((item) => {
        if (property === 'Product Details') {
          row[item['Equipment Name']] = (
            <a
              href={item[property]}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flex: '1 1',
                borderRadius: '4px',
                fontWeight: '600',
                textAlign: 'center',
                border: '2px solid transparent',
                backgroundColor: 'transparent',
                color: 'var(--colorBlue500-867f037e)',
                textDecoration: 'none',
              }}
            >
              {property}
            </a>
          );
        } else {
          row[item['Equipment Name']] = item[property];
        }
      });

      return row;
    });
  }, [data]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data: tableData });

  return (
    <div style={{overflowX: 'auto'}}>
    <table className="table-basic" {...getTableProps()} style={{ borderCollapse: 'collapse' }}>
      <thead>
        {headerGroups.map((headerGroup, index) => (
          <tr
            {...headerGroup.getHeaderGroupProps()}
            style={{ backgroundColor: index % 2 === 0 ? '#F6F5F5' : '#FFFFFF'}}
          >
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps()}
                className='col-title'
              >
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, index) => {
          prepareRow(row);
          return (
            <tr
              {...row.getRowProps()}
              style={{ backgroundColor: index % 2 === 0 ? '#FFFFFF' : '#F6F5F5'}}
            >
              {row.cells.map((cell, cellIndex) => {
                if (cell.value === 'Product Description') {
                  return (
                    <td
                      {...cell.getCellProps()}
                    >
                    <a
                        href={cell.value}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Product Description
                      </a>
                    </td>)}
               return (
                    <td
                      {...cell.getCellProps()}
                        className='row-cells'
                      >
                      <div className="box-container">
                      <div className="row-property">{cell.render('Cell')}</div>
                      </div>
                    </td>
                    );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
    </div>
  );
};

