import { useAtom } from 'jotai';
import {
    miniExAtom,
    smallExAtom,
    mediumExAtom,
    largeExAtom,
    underExAtom,
    overExAtom,
    mediumMaterialAtom,
    longMaterialAtom,
  } from './atoms';

// Array of equipment
const equipmentArray = [
    'HF-100 Mini',
    'HF-150',
    'HF-300',
    'HF-400',
    'HF-800 2S',
    'HF-800 2SG',
    'HF-800 SS',
    'HF-800 SSG',
    'n/a'
  ];

// Hook to determine the best equipment based on atom values
export const useBestExcavator = () => {
    const [miniExcavator] = useAtom(miniExAtom);
    const [smallExcavator] = useAtom(smallExAtom);
    const [mediumExcavator] = useAtom(mediumExAtom);
    const [largeExcavator] = useAtom(largeExAtom);
    const [lowPressure] = useAtom(underExAtom);
    const [highPressure] = useAtom(overExAtom);
    const [mediumMaterial] = useAtom(mediumMaterialAtom);
    const [largeMaterial] = useAtom(longMaterialAtom);
    
    if (miniExcavator) {
        if ((lowPressure && mediumMaterial) || (highPressure && (mediumMaterial || largeMaterial))) {
            return 'HF-400';
        } else {
            return 'n/a';
        }
    } else if (smallExcavator || mediumExcavator || largeExcavator) {
        if (highPressure && (mediumMaterial || largeMaterial)) {
            return ['HF-800 SS', 'HF-800 SSG'];
        } else if (lowPressure && (mediumMaterial || largeMaterial)) {
            return ['HF-800 2S', 'HF-800 2SG'];
        }
    }

    return 'n/a';
};