import React from "react";
import * as _Builtin from "./_Builtin";
import * as _utils from "./utils";
import _styles from "./FilterCardisSelected.module.css";

export function FilterCardisSelected({
  as: _Component = _Builtin.Block,
  title = "Title",
  description = "Description",
  id,
  showImage = true,
  showDescription = true,
  image = "https://uploads-ssl.webflow.com/649e7d984e2a9b5b23e56bf2/649e7d9b4e2a9b5b23e56e07_image.svg",
  filterCardProps = {},
}) {
  return (
    <_Component
      className={_utils.cx(_styles, "filter-card", "is-selected")}
      tag="div"
      id={id}
      {...filterCardProps}
    >
      <_Builtin.Block
        className={_utils.cx(_styles, "filter-card_check")}
        tag="div"
      >
        <_Builtin.HtmlEmbed
          className={_utils.cx(_styles, "icon-1x1-default")}
          value="%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Crect%20width%3D%2224%22%20height%3D%2224%22%20rx%3D%2212%22%20fill%3D%22%23FFBA3F%22%2F%3E%0A%3Cpath%20d%3D%22M17.3333%208L9.99996%2015.3333L6.66663%2012%22%20stroke%3D%22%23171718%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3C%2Fsvg%3E"
        />
      </_Builtin.Block>
      {showImage ? (
        <_Builtin.Image
          className={_utils.cx(_styles, "filter-card_image")}
          loading="lazy"
          width="Auto"
          height="auto"
          alt="__wf_reserved_inherit"
          src={image}
        />
      ) : null}
      <_Builtin.Block
        className={_utils.cx(_styles, "filter-card_content")}
        tag="div"
      >
        <_Builtin.Block
          className={_utils.cx(_styles, "filter-card_title")}
          tag="div"
        >
          {title}
        </_Builtin.Block>
        {showDescription ? (
          <_Builtin.Block
            className={_utils.cx(_styles, "filter-card_description")}
            tag="div"
          >
            {description}
          </_Builtin.Block>
        ) : null}
      </_Builtin.Block>
    </_Component>
  );
}
