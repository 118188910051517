import axios from 'axios';

export const sendMailTemplate = async (to, data, templateId) => {
  const emailData = { to, data, templateId };
  
  try {
    const response = await axios.post('https://selector.machinerypartner.com/api/sendEmail', emailData);
    if (response.data.success) {
      console.log('Email sent successfully');
    } else {
      console.error('Email sending failed');
    }
  } catch (error) {
    console.error('Error sending email:', error);
  }
};