import React, { useEffect } from "react";
import "../styles/loadingScreen.css"
import "../styles/mp-styles.css"


export function LoadingScreen() {

/*     useEffect(() => {
        const timer = setTimeout(() => {
            navigate('/results');
        }, 2000); 
        return () => clearTimeout(timer);
    }, [navigate]); */

    return (
        <>
            <div className="loading-screen">
                <div className="loader"></div>
                <h2 className="title">Finding attachments...</h2>
                <p className="description">We are selecting the best attachments for your equipment</p>
            </div>
        </>
    );
}