import "../styles/mp-styles.css"
import { SimplifiedHeader } from "../devlink/SimplifiedHeader.js"
import { SimplifiedFooter} from "../devlink/SimplifiedFooter.js"
import { useForm, FormProvider } from "react-hook-form";
import Field from "../components/fields/field"
import styles from "../devlink/ButtonPrimary.module.css";
import { FormContainer } from "../devlink/FormContainer.js"
import { PageLayout } from "../devlink/PageLayout";
import { ButtonDisabled } from "../devlink/ButtonDisabled";
import { useNavigate } from "react-router-dom";
import { airtableRecordIdAtom } from "../components/atoms";
import { useAtom } from 'jotai';


export const FormStep1 = () => {

    const navigate = useNavigate()
    const methods = useForm();
    const { watch } = methods;
    const machineTypeValue = watch("machineType");
    const [airtableRecordId] = useAtom(airtableRecordIdAtom);

    const formSchema = [
        {
          name: "machineType",
          label: "Confirm machine type",
          type: "SELECT",
          mode: "SELECT",
          options: [
            { label: "Skid Steer", value: "skid" },
            { label: "Excavator", value: "excavator" },
          ],
          validation: {required: true},
        },
    ]

    const handleNav = () => {
        if (machineTypeValue === "skid") {
            navigate(`/${airtableRecordId}/form-step2`);
        } else if (machineTypeValue === "excavator") {
            navigate(`/${airtableRecordId}/form-step2ex`);
        }
    }

    return (
        <>
        <PageLayout 
            
            contentSlot={[
                <FormContainer 
                    question={"Tell us a bit more about your equipment"}
                    showStepper={false}
                    description={"We require additional information regarding your equipment before we can finalize your order."}
                    buttonWrapper={[
                        (machineTypeValue === "skid" || machineTypeValue === "excavator") ? 
                        <button className={styles.button} onClick={handleNav}>Next</button> : 
                        <ButtonDisabled label={"Next"} />
                    ]}
                    formSlot={
                        <FormProvider {...methods}>
                            <form className="form" >
                                {formSchema.map((field, index) => (
                                    <Field key={index} {...field} />
                                ))}
                            </form>
                        </FormProvider>
                    }
                />
            ]}
        />
        </>
        )
  };