import "../styles/mp-styles.css"
import { useForm, FormProvider } from "react-hook-form";
import Field from "../components/fields/field"
import styles from "../devlink/ButtonPrimary.module.css";
import alternate from "../devlink/ButtonSecondary.module.css";
import { FormContainer } from "../devlink/FormContainer.js"
import exImg from "../assets/excavator-boom-stick-illustration.svg"
import { PageLayout } from "../devlink/PageLayout";
import { ButtonDisabled } from "../devlink/ButtonDisabled";
import { useNavigate } from "react-router-dom";
import { getFullNameAndEmailFromAirtable, updateMachineDetailsInAirtable } from "../components/airTableConfig";
import { airtableRecordIdAtom } from "../components/atoms";
import { useAtom } from 'jotai';
import { sendMailTemplate } from "../components/sendThankMail";


const formatDataForAirtable = (schema, values) => {
    return schema.map(field => `${field.label}: ${values[field.name]}`).join('; ');
};

export const FormStep2Ex = () => {

    const navigate = useNavigate()
    const methods = useForm();
    const { watch } = methods;
    const machineBrandValue = watch("excavatorBrand");
    const machineNrValue = watch("excavatorNr");
    const stickValue = watch("exStick");
    const boomValue = watch("exStick");
    const stickWidth = watch("stickWidth");
    const boomWidth = watch("boomWidth");
    const pinValue = watch("pinDistance");
    const [airtableRecordId] = useAtom(airtableRecordIdAtom);

    const machineDetails = [stickValue, boomValue, stickWidth, boomWidth, pinValue]


    const formSchema = [
        {
          name: "excavatorBrand",
          label: "Excavator Brand",
          type: "TEXT",
          placeholder: "Ex: Sunward, John Deer",
          /* defaultValue: "", */
          validation: {required: true},
        },
        {
            name: "excavatorNr",
            label: "Excavator model number",
            type: "TEXT",
            placeholder: "Ex: Sunward U18F",
            /* defaultValue: "", */
            validation: {required: true},
          },
    ];
    const formSchema2 = [
        {
          name: "exStick",
          label: "Stick Pin diameter (in inches)",
          type: "TEXT",
          placeholder: "",
          img: true,
          imgColor: 'var(--color-brand-400, #FFBA3F)',
          letter: 'A',
          letterColor: 'var(--color-gray-900, #171718)',
          validation: {required: true},
        },
        {
            name: "exBoom",
            label: "Boom Pin diameter (in inches)",
            type: "TEXT",
            placeholder: "",
            img: true,
            imgColor: 'var(--color-red-500, #FA5252)',
            letter: 'B',
            letterColor: 'var(--color-white, #FFF)',
            validation: {required: true},
          },
          {
            name: "stickWidth",
            label: "Stick width (in inches)",
            type: "TEXT",
            placeholder: "",
            img: true,
            imgColor: 'var(--color-blue-500, #228BE6)',
            letter: 'C',
            letterColor: 'var(--color-white, #FFF)',
            validation: {required: true},
          },
          {
            name: "boomWidth",
            label: "Boom width (in inches)",
            type: "TEXT",
            placeholder: "",
            img: true,
            imgColor: 'var(--color-green-500, #40C057)',
            letter: 'D',
            letterColor: 'var(--color-white, #FFF)',
            validation: {required: true},
          },
          {
            name: "pinDistance",
            label: "Distance between both pin holes (center to center in inches)",
            type: "TEXT",
            placeholder: "",
            img: true,
            imgColor: 'var(--color-gray-600, #525351)',
            letter: 'E',
            letterColor: 'var(--color-white, #FFF)',
            validation: {required: true},
          },
    ];
        
    const handleNav = async () => {
        try {
            const { fullName, email } = await getFullNameAndEmailFromAirtable(
                airtableRecordId
            );
            const formData1 = formatDataForAirtable(formSchema, methods.getValues());
            const formData2 = formatDataForAirtable(formSchema2, methods.getValues());
            const combinedData = `${formData1}; ${formData2}`;
            await updateMachineDetailsInAirtable(airtableRecordId, combinedData);
            await sendMailTemplate(
                email,
                {
                    subject: "Machinery Partner Cone Splitter Purchase",
                    customerName: fullName,
                  },
                "d-7a083fa04422434696b8f25cd946b98c"
                )
            navigate("/form-end");
        } catch (error) {
            console.error('Error processing data:', error);
          }
    }
    
    const backNav = () => {
        navigate(`/${airtableRecordId}/form-step1`);
    }

    return (
        <>
        <PageLayout 
            contentSlot={[
                <FormContainer 
                    question={"Tell us a bit more about your excavator"}
                    showStepper={false}
                    showDescription={false}
                    buttonWrapper={[
                        <button onClick={backNav} className={`${alternate.button} ${alternate['is-secondary']}`}>Back</button>,
                        (machineBrandValue && machineNrValue) ? 
                        (<button onClick={handleNav} className={styles.button}>Finalize Order</button>) : 
                        (<ButtonDisabled label={"Finalize Order"} />)
                    ]}
                    formSlot={
                        <FormProvider {...methods}>
                            <form className="form" >
                                {formSchema.map((field, index) => (
                                    <Field key={index} {...field} />
                                ))}
                                <div style={{ marginTop: "4rem" }}>
                                    <div className="text-style-label" style={{ marginBottom: '2rem', }}>Details about the Excavator Stick and Boom</div>
                                    <img src={exImg} style={{ marginBottom: '2rem', }} alt="excavator pin"/>
                                    {formSchema2.map((field, index) => (
                                        <Field key={index} {...field} />
                                    ))}
{/*                                     <div className="text-weight-semibold">Photo of the stick/boom of your equipment</div>
                                    <span className="text-size-tiny">Max file size is 2Mb. Supported file types are jpg, png and bmp</span>
                                    <button className="btn btn-upload">Upload File</button> */}
                                </div>
                                    
                            </form>
                        </FormProvider>
                    }
                />
            ]}
        />
        </>
        )
  };