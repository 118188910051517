import React, { useEffect, useState } from "react";
import { FilterCardDefault } from "../devlink/FilterCardDefault";
import { FilterCardisSelected } from "../devlink/FilterCardisSelected";
import { ButtonSecondary } from "../devlink/ButtonSecondary";
import { ButtonPrimary } from "../devlink/ButtonPrimary";
import { ButtonDisabled} from "../devlink/ButtonDisabled";
import { useNavigate } from "react-router-dom";
import styles  from "../devlink/ButtonPrimary.module.css";
import alternate from "../devlink/ButtonSecondary.module.css";
import { useAtom } from 'jotai';
import { standardSelectedAtom, highSelectedAtom } from '../components/atoms';
import { FormContainer } from "../devlink/FormContainer";
import { PageLayout } from "../devlink/PageLayout";
import mixpanel from "mixpanel-browser";

mixpanel.init("93036cc0bfa1ceab46ce5584c709ac7f");

export function ThirdStep() {

    const navigate = useNavigate()
    const [standardSelected, setStandardSelected] = useAtom(standardSelectedAtom);
    const [highSelected, setHighSelected] = useAtom(highSelectedAtom);

    const handleClick = () => {
        setStandardSelected(!standardSelected);
        setHighSelected(false);
    };
      
    const handleClick2 = () => {
        setHighSelected(!highSelected);
        setStandardSelected(false);
    };

    const handleNavigate = () => {
        let flowType = '';
        
        if (standardSelected) {
            flowType = 'Standard';
        } else if (highSelected) {
            flowType = 'High';
        }
        
        mixpanel.track("Skid Steer Flow Type", {
            'Flow Type': flowType
        });
        navigate(`/4`);

    }

    const handleBackButton = () => {
        navigate(`/2`);
    }

    useEffect(() => {
        const beforeUnloadHandler = () => {
          mixpanel.track("Abandoned Skid Steer Third step");
        };
        
        window.addEventListener("beforeunload", beforeUnloadHandler);
    
        return () => {
          window.removeEventListener("beforeunload", beforeUnloadHandler);
        };
      }, []);

    return (
        <>
        <PageLayout
            contentSlot={[
                <FormContainer
                question={"Does your skid steer have standard or high flow hydraulics?"}
                step={"3 / 5"}
                showDescription={false}
                formSlot={[
                    standardSelected ? (
                        <FilterCardisSelected
                            title={"Standard Flow"}
                            showImage={false}
                            showDescription={true}
                            description={"17 - 25 GPM"}
                            filterCardProps={{ onClick: handleClick }}
                        />
                    ) : (
                        <FilterCardDefault 
                            title={"Standard Flow"}
                            showImage={false}
                            showDescription={true}
                            description={"17 - 25 GPM"}
                            filterCardProps={{onClick: handleClick}}
                    />
                    ),
                    highSelected ? (
                        <FilterCardisSelected 
                            title={"High Flow"}
                            showImage={false}
                            showDescription={true}
                            description={"30 - 40 GPM"}
                            filterCardProps={{ onClick: handleClick2 }}
                        />
                        ) : (
                        <FilterCardDefault 
                            title={"High Flow"}
                            showImage={false}
                            showDescription={true}
                            description={"30 - 40 GPM"}
                            filterCardProps={{ onClick: handleClick2 }}
                        />
                    )
                    ]}
                    buttonWrapper={[
                        <button className={`${alternate.button} ${alternate['is-secondary']}`} onClick={handleBackButton}>Back</button>,
                        standardSelected || highSelected ? (
                            <button className={styles.button} onClick={handleNavigate}>Next</button>
                        ) : (
                        <ButtonDisabled 
                            label={"Next"}
                        />)
                    ]}
                />
            ]}
        />

        </>
    );
}