import { useAtom } from 'jotai';
import {
  miniSkidAtom,
  mediumSkidAtom,
  largeSkidAtom,
  standardSelectedAtom,
  highSelectedAtom,
  shortSelectedAtom,
  mediumSelectedAtom,
  longSelectedAtom,
} from './atoms';

// Array of equipment
const equipmentArray = [
  'HF-100 Mini',
  'HF-150',
  'HF-300',
  'HF-400',
  'HF-800 2S',
  'HF-800 2SG',
  'HF-800 SS',
  'HF-800 SSG',
  'n/a'
];

// Hook to determine the best equipment based on atom values
export const useBestSkid = () => {
  const [miniSkid] = useAtom(miniSkidAtom);
  const [mediumSkid] = useAtom(mediumSkidAtom);
  const [largeSkid] = useAtom(largeSkidAtom);
  const [standardSelected] = useAtom(standardSelectedAtom);
  const [highSelected] = useAtom(highSelectedAtom);
  const [shortSelected] = useAtom(shortSelectedAtom);
  const [mediumSelected] = useAtom(mediumSelectedAtom);
  const [longSelected] = useAtom(longSelectedAtom);

  if (miniSkid) {
    if (shortSelected && (standardSelected || highSelected)) {
      return 'HF-100 Mini';
    } else if (shortSelected && !standardSelected && !highSelected) {
      return 'HF-100 Mini';
    } else {
      return 'n/a';
    }
  } else if (mediumSkid) {
    if (shortSelected && standardSelected) {
      return 'HF-150';
    } else if (shortSelected && highSelected) {
      return 'HF-300';
    } else if (mediumSelected && standardSelected) {
      return 'HF-150';
    } else if (mediumSelected && highSelected) {
      return 'HF-300';
    } else if (longSelected && standardSelected) {
      return 'n/a';
    } else if (longSelected && highSelected) {
      return 'HF-300';
    }
  } else if (largeSkid) {
    if (shortSelected && standardSelected) {
      return 'HF-150';
    } else if (shortSelected && highSelected) {
      return 'HF-300';
    } else if (mediumSelected && standardSelected) {
      return 'HF-300';
    } else if (mediumSelected && highSelected) {
      return 'HF-300';
    } else if (longSelected && standardSelected) {
      return 'HF-300';
    } else if (longSelected && highSelected) {
      return 'HF-800 2S';
    }
  }

  return 'n/a';
};

// Usage example
const MyComponent = () => {
  const bestEquipment = useBestSkid();
  console.log(bestEquipment);
  // ...
};