import React from "react";
import * as _Builtin from "./_Builtin";
import { GlobalStyles } from "./GlobalStyles";
import { SimplifiedHeader } from "./SimplifiedHeader";
import { SimplifiedFooter } from "./SimplifiedFooter";
import * as _utils from "./utils";
import _styles from "./ResultPage.module.css";

export function ResultPage({
  as: _Component = _Builtin.Block,
  pageTitle = "Results",
  pageSubtitle = "Good news, we found these options that match your needs",
  resultSlot,
  tableSlot,
  pairedSlot,
  showPairedWith = true,
  resultProp = {},
}) {
  return (
    <_Component
      className={_utils.cx(_styles, "page-wrapper")}
      tag="div"
      {...resultProp}
    >
      <GlobalStyles />
      <_Builtin.Block
        className={_utils.cx(_styles, "main-wrapper", "is-flex")}
        tag="main"
      >
        <SimplifiedHeader />
        <_Builtin.Block
          className={_utils.cx(
            _styles,
            "section_title",
            "background-color-default"
          )}
          tag="header"
        >
          <_Builtin.Block
            className={_utils.cx(_styles, "padding-global")}
            tag="div"
          >
            <_Builtin.Block
              className={_utils.cx(_styles, "title-container")}
              tag="div"
            >
              <_Builtin.Heading
                className={_utils.cx(_styles, "heading-style-h3")}
                tag="h1"
              >
                {pageTitle}
              </_Builtin.Heading>
              <_Builtin.Block
                className={_utils.cx(_styles, "text-color-weak")}
                tag="div"
              >
                {pageSubtitle}
              </_Builtin.Block>
            </_Builtin.Block>
          </_Builtin.Block>
        </_Builtin.Block>
        <_Builtin.Block
          className={_utils.cx(
            _styles,
            "section_results",
            "background-color-default"
          )}
          tag="section"
        >
          <_Builtin.Block
            className={_utils.cx(_styles, "padding-global")}
            tag="div"
          >
            <_Builtin.Block
              className={_utils.cx(_styles, "container-large")}
              tag="div"
            >
              <_Builtin.Block
                className={_utils.cx(_styles, "results-grid")}
                tag="div"
              >
                {resultSlot}
              </_Builtin.Block>
              <_Builtin.Block
                className={_utils.cx(_styles, "compare-buttons")}
                id={_utils.cx(
                  _styles,
                  "w-node-f54b1020-0100-c0b8-be6b-2a544a7d80c8-4a7d80b9"
                )}
                tag="div"
              >
                <_Builtin.Link
                  className={_utils.cx(_styles, "button", "is-secondary")}
                  button={true}
                  options={{
                    href: "#compare",
                  }}
                >
                  {"Compare Models"}
                </_Builtin.Link>
                <_Builtin.Link
                  className={_utils.cx(
                    _styles,
                    "button",
                    "is-text",
                    "text-color-brand"
                  )}
                  button={true}
                  options={{
                    href: "https://www.machinerypartner.com/contact",
                    target: "_blank",
                  }}
                >
                  {"Not sure? Talk to an expert!"}
                </_Builtin.Link>
              </_Builtin.Block>
            </_Builtin.Block>
          </_Builtin.Block>
        </_Builtin.Block>
        <_Builtin.Block
          className={_utils.cx(_styles, "section_table")}
          tag="section"
          id="compare"
        >
          <_Builtin.Block
            className={_utils.cx(_styles, "padding-global")}
            tag="div"
          >
            <_Builtin.Block
              className={_utils.cx(_styles, "table-container")}
              tag="div"
            >
              <_Builtin.Heading
                className={_utils.cx(_styles, "heading-style-h4")}
                tag="h2"
              >
                {"Compare Models"}
              </_Builtin.Heading>
              <_Builtin.Block
                className={_utils.cx(_styles, "table-slot")}
                tag="div"
              >
                {tableSlot}
              </_Builtin.Block>
            </_Builtin.Block>
          </_Builtin.Block>
        </_Builtin.Block>
        {showPairedWith ? (
          <_Builtin.Block
            className={_utils.cx(
              _styles,
              "section_paired-with",
              "background-color-default"
            )}
            tag="section"
          >
            <_Builtin.Block
              className={_utils.cx(_styles, "padding-global")}
              tag="div"
            >
              <_Builtin.Block
                className={_utils.cx(_styles, "paried-container")}
                tag="div"
              >
                <_Builtin.Heading
                  className={_utils.cx(_styles, "heading-style-h4")}
                  tag="h3"
                >
                  {"Commonly paired with"}
                </_Builtin.Heading>
                <_Builtin.Block
                  className={_utils.cx(_styles, "paired-with-grid")}
                  tag="div"
                >
                  {pairedSlot}
                </_Builtin.Block>
              </_Builtin.Block>
            </_Builtin.Block>
          </_Builtin.Block>
        ) : null}
        <SimplifiedFooter />
      </_Builtin.Block>
    </_Component>
  );
}
