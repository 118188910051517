import React from "react";
import * as _Builtin from "./_Builtin";
import * as _utils from "./utils";
import _styles from "./SimplifiedFooter.module.css";

export function SimplifiedFooter({ as: _Component = _Builtin.Block }) {
  return (
    <_Component
      className={_utils.cx(_styles, "simplified-footer")}
      tag="footer"
    >
      <_Builtin.Block
        className={_utils.cx(_styles, "padding-global")}
        tag="div"
      >
        <_Builtin.Block
          className={_utils.cx(_styles, "container-large")}
          tag="div"
        >
          <_Builtin.Block
            className={_utils.cx(_styles, "simplified-footer_wrapper")}
            tag="div"
          >
            <_Builtin.HtmlEmbed
              className={_utils.cx(_styles, "simplified-footer_copyright")}
              value="%3Cp%20class%3D%22simplified-footer_copyright%22%3E%3Cscript%3Edocument.write(new%20Date().getFullYear())%3C%2Fscript%3E%20Copyright%20%C2%A9%20Machinery%20Partner"
            />
            <_Builtin.Block
              className={_utils.cx(
                _styles,
                "divider-vertical",
                "hide-mobile-portrait"
              )}
              tag="div"
            />
            <_Builtin.Link
              className={_utils.cx(_styles, "simplified-footer_link")}
              button={false}
              options={{
                href: "https://www.machinerypartner.com/privacy-policy",
                target: "_blank",
              }}
            >
              {"Privacy"}
            </_Builtin.Link>
            <_Builtin.Block
              className={_utils.cx(
                _styles,
                "divider-vertical",
                "hide-mobile-portrait"
              )}
              tag="div"
            />
            <_Builtin.Link
              className={_utils.cx(_styles, "simplified-footer_link")}
              button={false}
              options={{
                href: "https://www.machinerypartner.com/terms-of-use",
                target: "_blank",
              }}
            >
              {"Terms"}
            </_Builtin.Link>
          </_Builtin.Block>
        </_Builtin.Block>
      </_Builtin.Block>
    </_Component>
  );
}
