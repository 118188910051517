import { Table } from "./table";
import tabletStyles from "../styles/tabletStyles.css"
import { excavatorAtom, skidAtom } from './atoms';
import { useBestSkid } from "./skidLogic"
import { useBestExcavator } from "./excavatorLogic";
import { useAtom } from 'jotai';
import { useEffect, useState, forwardRef  } from "react";

const slugify = require("slugify");
const baseUrl = "https://www.machinerypartner.com/heavy-equipment/"

const slugifyOptions = {
  replacement: "-", // replace spaces with replacement character, defaults to `-`
  remove: undefined, // remove characters that match regex, defaults to `undefined`
  lower: true, // convert to lower case, defaults to `false`
  strict: true, // strip special characters except replacement, defaults to `false`
  locale: "vi", // language code of the locale to use
  trim: true, // trim leading and trailing replacement chars, defaults to `true`
};

export const CompareTable = forwardRef((props, ref) => {
    
  const [isExcavator] = useAtom(excavatorAtom);
  const [isSkid] = useAtom(skidAtom);
  const bestExcavatorEquipment = useBestExcavator();
  const bestSkidEquipment = useBestSkid();
  const [equipmentData, setEquipmentData] = useState([]);
  
  function formatSingleEquipment(item) {
    let formattedItem = item.toLowerCase();
    formattedItem = formattedItem.replace(/\b2sg\b/g, '2-speed-grapple');
    formattedItem = formattedItem.replace(/\bssg\b/g, 'single-speed-grapple');
    formattedItem = formattedItem.replace(/\bss\b/g, 'single-speed');
    formattedItem = formattedItem.replace(/\b2s\b/g, '2-speed');
    formattedItem = formattedItem.replace(/\s+/g, '-');
    return formattedItem;
  }

  const getBestEq = async () => {
    let bestEquipment;
    if (isExcavator) {
      bestEquipment = bestExcavatorEquipment;
    } else if (isSkid) {
      bestEquipment = bestSkidEquipment;
    } else {
      bestEquipment = "n/a";
    }
    
    if (bestEquipment !== "n/aaaa") {
      let formattedEq;
      if (Array.isArray(bestEquipment)) {
        formattedEq = bestEquipment.join(',');
      } else {
        formattedEq = bestEquipment;
      }
      try {
        const response = await fetch(
          `https://mp-loan-application.vercel.app/api/models/compare-models?names=${formattedEq}`
        );
        if (response.ok) {
          const data = await response.json();

          const equipmentData = data.map((item) => ({
            'Equipment Name': item.name,
            'Splitter Weight (lbs)': item.specs.attachment_weight.replace(/ lbs/i, ""),
            'Cone Size (length x base)': item.specs.cone_size,
            'Shaft Diameter (inches)': item.specs.shaft_diameter,
            'Max Split Size (length in feet)': item.specs.max_split_size,
            'Oil Quantity (gpm)': item.specs.oil_flow_rate.replace(/ gpm/i, ""),
            'Oil Pressure (PSI)': item.specs.oil_working_pressure.replace(/ psi/i, ""),
            'Torque (ft-lbs)': item.specs.torque.replace(/ ft-lbs/i, ""),
            'Carrier Weight (lbs)': item.specs.carrier_weight.replace(/ lbs/i, ""),
            'Product Details': `https://www.machinerypartner.com/heavy-equipment/${slugify(`${item.manufacturer.name} ${formatSingleEquipment(item.name)} ${item.category.slug.slice(0, -1)}`, slugifyOptions)}`
            }))

          setEquipmentData(equipmentData);
        } else {
          console.error("Error fetching data:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    else {
      console.log("No Equipment to show")
    }
  };

  useEffect(() => {
      async function fetchData() {
        const data = await getBestEq();
      }
      fetchData();
    }, []);
  
    return (
        <>
          <Table data={equipmentData} />
        </>
        )
  });


/*   {
    'Equipment Name': 'HF-700',
    'Splitter Weight (lbs)': '750',
    'Cone Size (length x base)': '26 x 12',
    'Shaft Diameter (inches)': '3.56',
    'Max Split Size (length in feet)': '12',
    'Oil Quantity (gpm)': '15 - 32',
    'Oil Pressure (PSI)': '2,400 - 3,400',
    'Torque (ft-lbs)': '3,600',
    'Carrier Weight (lbs)': '6,000 - 20,000',
    'Product Details': 'Product Description',
  }, */