import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import "../../styles/mp-styles.css"

const SelectField = ({
    label,
    name,
    options,
    mode,
    defaultValue,
    validation
  }) => {
    const { register, setValue, watch, formState: { errors } } = useFormContext();
    const selectedValue = watch(name);
  
    useEffect(() => {
      if (defaultValue) {
        setValue(name, defaultValue);
      }
    }, []);
  
    return (
      <div className="input-container">
        <label htmlFor={name} {...(validation.required ? { 'data-required': 'true'} : {})} className="input-label">{label}</label>
        {mode === "SELECT" ? (
          <select {...register(name, validation)} id={name} placeholder={"Please select..."} className={`input-field ${errors[name]?.type === "required" ? ('border-[#FA5252] bg-[#FFF5F5]') : ('border-neutral-300')}`}>
            <option value="" disabled selected hidden>Please select...</option>
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        ) : (
          <div {...register(name, validation)} >
            {options.map((option) => (
              <button
                type="reset"
                key={option.value}
                onClick={() => setValue(name, option.value)}
                value={option.value}
                className={
                  selectedValue === option.value
                    ? ""
                    : ""
                }
              >
                {option.label}
              </button>
            ))}
          </div>
        )}
        {errors[name] ? (
          <>
            {errors[name]?.type === "required" ? (
              <>
                <span className="input-error">
                  Please select an option.
                </span>
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
      </div>
    );
  };
  
  export default SelectField;
  