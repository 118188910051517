import "../styles/mp-styles.css"
import { SimplifiedHeader } from "../devlink/SimplifiedHeader.js"
import { SimplifiedFooter} from "../devlink/SimplifiedFooter.js"
import { useForm, FormProvider } from "react-hook-form";
import Field from "../components/fields/field"
import styles from "../devlink/ButtonPrimary.module.css";
import alternate from "../devlink/ButtonSecondary.module.css";
import { FormContainer } from "../devlink/FormContainer.js"
import { PageLayout } from "../devlink/PageLayout";
import { ButtonDisabled } from "../devlink/ButtonDisabled";
import { useNavigate } from "react-router-dom";
import { getFullNameAndEmailFromAirtable, pushDataToAirtable, updateMachineDetailsInAirtable } from "../components/airTableConfig";
import { airtableRecordIdAtom } from "../components/atoms";
import { useAtom } from 'jotai';
import { sendMailTemplate } from "../components/sendThankMail";

const formatDataForAirtable = (schema, values) => {
    return schema.map(field => `${field.label}: ${values[field.name]}`).join('; ');
};

export const FormStep2 = () => {

    const navigate = useNavigate()
    const methods = useForm();
    const { watch } = methods;
    const machineBrandValue = watch("machineBrand");
    const machineNrValue = watch("machineNr");
    const [airtableRecordId] = useAtom(airtableRecordIdAtom);

    const formSchema = [
        {
          name: "machineBrand",
          label: "Machine Brand",
          type: "TEXT",
          placeholder: "Ex: Sunward, Bobcat",
          /* defaultValue: "", */
          validation: {required: true},
        },
        {
            name: "machineNr",
            label: "Model Number",
            type: "TEXT",
            placeholder: "Ex: Sunward SWL2830",
            /* defaultValue: "", */
            validation: {required: true},
          },
    ]

    
    const handleNav = async () => {
        try {
            const { fullName, email } = await getFullNameAndEmailFromAirtable(
              airtableRecordId
            );
            const formData = formatDataForAirtable(formSchema, methods.getValues());
            await updateMachineDetailsInAirtable(airtableRecordId, formData)
            await sendMailTemplate(
                email,
                {
                    subject: "Machinery Partner Cone Splitter Purchase",
                    customerName: fullName,
                  },
                "d-7a083fa04422434696b8f25cd946b98c"
                )
            navigate("/form-end");
            } catch (error) {
              console.error('Error processing data:', error);
            }
        };
    

    
    const backNav = () => {
        navigate(`/${airtableRecordId}/form-step1`);
    }

    return (
        <>
                <PageLayout 
            contentSlot={[
                <FormContainer 
                    question={"Tell us a bit more about your skid steer"}
                    showStepper={false}
                    showDescription={false}
                    buttonWrapper={[
                        <button onClick={backNav} className={`${alternate.button} ${alternate['is-secondary']}`}>Back</button>,
                        (machineBrandValue && machineNrValue) ? 
                        (<button onClick={handleNav} className={styles.button}>Finalize Order</button>) : 
                        (<ButtonDisabled label={"Finalize Order"} />)
                    ]}
                    formSlot={
                        <FormProvider {...methods}>
                            <form className="form" >
                                {formSchema.map((field, index) => (
                                    <Field key={index} {...field} />
                                ))}
{/*                                 <div className="text-weight-semibold">Photo of your Skid Steer attachment bracket</div>
                                <span className="text-size-tiny">Max file size is 2Mb. Supported file types are jpg, png and bmp</span>
                                <button className="btn btn-upload">Upload File</button> */}
                            </form>
                        </FormProvider>
                    }
                />
            ]}
        />
        </>
        )
  };