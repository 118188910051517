import React, { useEffect, useState } from "react";
import { FilterCardDefault } from "../devlink/FilterCardDefault";
import { FilterCardisSelected } from "../devlink/FilterCardisSelected";
import { ButtonSecondary } from "../devlink/ButtonSecondary";
import { ButtonPrimary } from "../devlink/ButtonPrimary";
import { ButtonDisabled} from "../devlink/ButtonDisabled";
import styles  from "../devlink/ButtonPrimary.module.css";
import { useNavigate } from "react-router-dom";
import alternate from "../devlink/ButtonSecondary.module.css";
import { useAtom } from 'jotai';
import { overExAtom, underExAtom } from "../components/atoms";
import { FormContainer } from "../devlink/FormContainer";
import { PageLayout } from "../devlink/PageLayout";
import mixpanel from "mixpanel-browser";

mixpanel.init("93036cc0bfa1ceab46ce5584c709ac7f");

export function ThirdStepAlt() {

    const navigate = useNavigate()
    const [underExSelected, setUnderExSelected] = useAtom(underExAtom);
    const [overExSelected, setOverExSelected] = useAtom(overExAtom);

    const handleClick = () => {
        setUnderExSelected(!underExSelected)
        setOverExSelected(false)
    };
    const handleClick2 = () => {
        setOverExSelected(!overExSelected)
        setUnderExSelected(false)
    };

    const handleNavigate = () => {
        let pressureType = '';
        
        if (underExSelected) {
            pressureType = 'Under 4000 PSI';
        } else if (overExSelected) {
            pressureType = 'Over 4000 PSI';
        }
        
        mixpanel.track("Excavator Oil Pressure", {
            'Pressure': pressureType
        });
        navigate(`/4a`);
    }

    const handleBackButton = () => {
        navigate(`/2a`);
    }

    useEffect(() => {
        const beforeUnloadHandler = () => {
          mixpanel.track("Abandoned Excavator Third Step");
        };
        
        window.addEventListener("beforeunload", beforeUnloadHandler);
    
        return () => {
          window.removeEventListener("beforeunload", beforeUnloadHandler);
        };
      }, []);

    return (
        <>
        <PageLayout 
            contentSlot={[
                <FormContainer
                question={"What is the hydraulic oil pressure of your excavator?"}
                step={"3 / 5"}
                showDescription={false}
                formSlot={[
                    underExSelected ? (
                        <FilterCardisSelected
                            title={"Under 4000 PSI"}
                            showImage={false}
                            showDescription={false}
                            //description={"17 - 25 GPM"}
                            filterCardProps={{ onClick: handleClick }}
                        />
                    ) : (
                        <FilterCardDefault 
                            title={"Under 4000 PSI"}
                            showImage={false}
                            showDescription={false}
                            //description={"17 - 25 GPM"}
                            filterCardProps={{onClick: handleClick}}
                    />
                    ),
                    overExSelected ? (
                    <FilterCardisSelected 
                            title={"Over 4000 PSI"}
                            showImage={false}
                            showDescription={false}
                            //description={"30 - 40 GPM"}
                            filterCardProps={{ onClick: handleClick2 }}
                        />
                        ) : (
                        <FilterCardDefault 
                            title={"Over 4000 PSI"}
                            showImage={false}
                            showDescription={false}
                            //description={"30 - 40 GPM"}
                            filterCardProps={{ onClick: handleClick2 }}
                        />
                    )
                    ]}
                    buttonWrapper={[
    /*                     <ButtonSecondary 
                            label={"Back"}
                            link={{href: "/2a",}} 
                        />, */
                        <button className={`${alternate.button} ${alternate['is-secondary']}`} onClick={handleBackButton}>Back</button>,
                        underExSelected || overExSelected ? (
    /*                         <ButtonPrimary 
                                label={"Next"}
                                link={{href: "/4a",}}
                            /> */
                            <button className={styles.button} onClick={handleNavigate}>Next</button>
                        ) : (
                        <ButtonDisabled 
                            label={"Next"}
                        />)
                    ]}
                />
            ]}
        />
        </>
    );
}