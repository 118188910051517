import "../src/devlink/global.css";
import { createBrowserRouter, useLocation, useRouteError, RouterProvider, useNavigate } from "react-router-dom";
import { FirstStep } from "./steps/first";
import { SecondStep } from "./steps/second";
import { ThirdStep } from "./steps/third";
import { FourthStep } from "./steps/fourth";
import { SecondStepAlt } from "./steps/second-alt";
import { ThirdStepAlt } from "./steps/third-alt";
import { FourthStepAlt } from "./steps/fourth-alt";
import { CompareTable } from "./components/compare";
import { ResultOptions } from "./components/recommendations";
import { PairedWith } from "./components/paired";
import { ResultsPage } from "./steps/results";
import { LoadingScreen } from "./components/LoadingScreen";
import { MarketingPage } from "./components/marketingPage";
import { FormStep1 } from "./steps/formStep1";
import { FormStep2 } from "./steps/formStep2skid";
import { FormStep2Ex } from "./steps/formStep2ex";
import { useAtom } from 'jotai';
import { airtableRecordIdAtom, excavatorAtom, resultsPageState, skidAtom } from './components/atoms';
import { useBestSkid } from "./components/skidLogic"
import { useBestExcavator } from "./components/excavatorLogic";
import { useState, useEffect } from 'react'
import { ThankYou } from "./steps/thankYou";

function ErrorBoundary() {
  let error = useRouteError();
  console.error(error);
  return <div>Error!</div>;
}

function simulateDataLoading(delay) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

function loadApp() {
}

const router = createBrowserRouter([
  {
    path: "/", // 
    element: (
      <FirstStep />
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/2", // 
    element: (
      <SecondStep />
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/3", // 
    element: (
      <ThirdStep />
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/4", // 
    element: (
      <FourthStep />
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/2a", // 
    element: (
      <SecondStepAlt />
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/3a", // 
    element: (
      <ThirdStepAlt />
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/4a", // 
    element: (
      <FourthStepAlt />
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/loading", // 
    element: (
      <LoadingScreen />
    ),
    /* loader: loadApp, */
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/results/*", // 
    element: (
      <ResultsPage />
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/5", // 
    element: (
      <MarketingPage />
    ),
    /* loader: loadApp, */
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/:airtableRecordId/form-step1", // 
    element: (
      <FormStep1 />
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/:airtableRecordId/form-step2", // 
    element: (
      <FormStep2 />
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/:airtableRecordId/form-step2ex", // 
    element: (
      <FormStep2Ex />
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/form-end", // 
    element: (
      <ThankYou />
    ),
    errorElement: <ErrorBoundary />,
  },
]);

function App() {
  
  const [airtableRecordId, setAirtableRecordId] = useAtom(airtableRecordIdAtom);
  const [resultPageActive] = useAtom(resultsPageState);

  useEffect(() => {
    const pathnameSegments = window.location.pathname.split('/');
    const recordIdFromUrl = pathnameSegments[1];
    setAirtableRecordId(recordIdFromUrl);
  }, []);

  useEffect(() => {
    const exceptions = ["/2", "/2a", "/3", "/3a", "/4", "/4a", "/5", "/results"];
    if (exceptions.includes(window.location.pathname)) {
      window.location.pathname = '/';
    }
  }, []);

  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;