import React from "react";
import * as _Builtin from "./_Builtin";
import * as _utils from "./utils";
import _styles from "./ResultCard.module.css";

export function ResultCard({
  as: _Component = _Builtin.Block,
  isRecommended = true,
  productImage = "https://uploads-ssl.webflow.com/649e7d984e2a9b5b23e56bf2/649e7d9b4e2a9b5b23e56e07_image.svg",
  productName = "Product Name",
  brand = "Brand",
  category = "Category",
  price = "Price",

  buyNowUrl = {
    href: "#",
  },

  detailsUrl = {
    href: "#",
  },

  id,
  resultCardProp = {},
  detailsLabel = "Product Details",
  buyNowLabel = "Buy Now",
  showSecondaryBtn = true,

  imageLink = {
    href: "#",
  },
}) {
  return (
    <_Component
      className={_utils.cx(_styles, "result-card")}
      id={_utils.cx(
        _styles,
        "w-node-b1a21a07-4a20-7567-70d2-62d268f21d22-68f21d22"
      )}
      tag="div"
      {...resultCardProp}
    >
      {isRecommended ? (
        <_Builtin.Block
          className={_utils.cx(_styles, "result-card_recommended")}
          tag="div"
        >
          <_Builtin.HtmlEmbed
            className={_utils.cx(_styles, "icon-1x1-default")}
            value="%3Csvg%20width%3D%2225%22%20height%3D%2224%22%20viewBox%3D%220%200%2025%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M9.5%2012L11.5%2014L15.5%2010M12.5%2022C18.023%2022%2022.5%2017.523%2022.5%2012C22.5%206.477%2018.023%202%2012.5%202C6.977%202%202.5%206.477%202.5%2012C2.5%2017.523%206.977%2022%2012.5%2022Z%22%20stroke%3D%22%23171718%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3C%2Fsvg%3E"
          />
          <_Builtin.Block
            className={_utils.cx(_styles, "text-style-label")}
            tag="div"
          >
            {"Recommended"}
          </_Builtin.Block>
        </_Builtin.Block>
      ) : null}
      <_Builtin.Block
        className={_utils.cx(_styles, "result-card_wrapper")}
        tag="div"
      >
        <_Builtin.Link
          className={_utils.cx(_styles, "result-card_image")}
          button={false}
          options={imageLink}
        >
          <_Builtin.Image
            className={_utils.cx(_styles, "result-card_image")}
            loading="lazy"
            width="auto"
            height="auto"
            alt="__wf_reserved_inherit"
            src={productImage}
          />
        </_Builtin.Link>
        <_Builtin.Block
          className={_utils.cx(_styles, "result-card_content")}
          tag="div"
        >
          <_Builtin.Block
            className={_utils.cx(_styles, "result-card_title")}
            tag="div"
          >
            {productName}
          </_Builtin.Block>
          <_Builtin.Block
            className={_utils.cx(_styles, "result-card_category")}
            tag="div"
          >
            <_Builtin.Block
              className={_utils.cx(_styles, "brand-label")}
              tag="div"
            >
              {brand}
            </_Builtin.Block>
            <_Builtin.Block
              className={_utils.cx(_styles, "divider-vertical")}
              tag="div"
            />
            <_Builtin.Block
              className={_utils.cx(_styles, "category-label")}
              tag="div"
            >
              {category}
            </_Builtin.Block>
          </_Builtin.Block>
          <_Builtin.Block
            className={_utils.cx(
              _styles,
              "text-size-medium",
              "text-weight-semibold"
            )}
            tag="div"
          >
            {price}
          </_Builtin.Block>
        </_Builtin.Block>
      </_Builtin.Block>
      <_Builtin.Block
        className={_utils.cx(_styles, "result-card_btn-wrapper")}
        tag="div"
      >
        <_Builtin.Link
          className={_utils.cx(_styles, "button")}
          button={true}
          options={buyNowUrl}
        >
          {buyNowLabel}
        </_Builtin.Link>
        {showSecondaryBtn ? (
          <_Builtin.Link
            className={_utils.cx(_styles, "button", "is-text")}
            button={true}
            options={detailsUrl}
          >
            {detailsLabel}
          </_Builtin.Link>
        ) : null}
      </_Builtin.Block>
    </_Component>
  );
}
