import React, { useEffect } from "react";
import "../styles/mp-styles.css"
import { PageLayout } from "../devlink/PageLayout";
import { FormContainer } from "../devlink/FormContainer";
import styles  from "../devlink/ButtonPrimary.module.css";
import alternate from "../devlink/ButtonGhost.module.css";
import InputField from "./fields/inputField";
import { useForm, FormProvider } from "react-hook-form";
import Field from "./fields/field";
import { useNavigate } from "react-router-dom";
import { ButtonDisabled } from "../devlink/ButtonDisabled";
import mixpanel from "mixpanel-browser";
import GTM from 'react-gtm-module';
import { ConsentBlock } from "../devlink/ConsentBlock.js";

export function MarketingPage() {

    mixpanel.init("93036cc0bfa1ceab46ce5584c709ac7f");

    const navigate = useNavigate()
    const methods = useForm();
    const SERVER_URL = process.env.REACT_APP_SERVER_URL || 'http://localhost:5000';

    const formSchema = [
        {
            name: "fullName",
            label: "Full Name",
            type: "TEXT",
            placeholder: "",
            /* defaultValue: "", */
            validation: {required: false},
          },
          {
              name: "email",
              label: "Email",
              type: "TEXT",
              placeholder: "",
              /* defaultValue: "", */
              validation: {required: false},
            },
    ]

    const splitFullName = (fullName) => {
        const parts = fullName.trim().split(/\s+/);
        const firstName = parts[0] || "";
        const lastName = parts.slice(1).join(' ') || "";
        return { firstName, lastName };
    }

    const getCookie = (name) => {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
      return '';
    };

    const { watch } = methods;
    const fullNameValue = watch("fullName");
    const emailValue = watch("email");

    const isFieldsFilled = fullNameValue && emailValue;

    const handleSubmit = async (event, data) => {
        event.preventDefault();
        const hutk = getCookie('hubspotutk');
        const { firstName, lastName } = splitFullName(data.fullName);
        if (isFieldsFilled) {
            try {
              const payload = {
                "fields": [
                  {
                    "name": "email",
                    "value": data.email
                  },
                  {
                    "name": "firstname",
                    "value": firstName
                  },
                  { 
                    "name": "lastname",
                    "value": lastName
                  }
                ],
                "context": {
                  "hutk": hutk,
                  "ipAddress": "PLACEHOLDER_IP",
                  "pageUri": "https://selector.machinerypartner.com/",
                  "pageName": "Attachment Finder"
                },
                "legalConsentOptions": {
                  "consent": {
                    "consentToProcess": true,
                    "text": "I agree to allow Machinery Partner to store and process my personal data.",
                    "communications": [
                      {
                        "value": true,
                        "subscriptionTypeId": 999,
                        "text": "I agree to receive marketing communications from Machinery Partner."
                      }
                    ]
                  }
                }
              };
              const response = await fetch(`/api/createContact`, {
                method: 'POST',
                headers: {
                  Authorization: `${process.env.HUBSPOT_API_KEY}`,
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
              });
              // sending info to Google Tag Manager
              const GTMevent = {
                event: 'Lead Capture', 
                email: data.email,
                name: data.fullName,
              }
              window.dataLayer.push(GTMevent);
              console.log("resp", response)
              if (response.ok) {
                console.log("Contact created successfully");
              } else {
                const responseData = await response.json();
                console.log("Error creating contact:", responseData);
              }
            } catch (error) {
              console.error("API call error:", error);
            }
        }
        mixpanel.track("Agreed to Lead Collection")
        if (data.email) {
          mixpanel.identify(data.email)
        }
        navigate(`/results`)
    };

    const handleSkip = () => {
        mixpanel.track("Skipped Lead Collection", 
        )
        navigate(`/results`)
    }

    useEffect(() => {
        const beforeUnloadHandler = () => {
          mixpanel.track("Abandoned at Lead Collection");
        };
        
        window.addEventListener("beforeunload", beforeUnloadHandler);
    
        return () => {
          window.removeEventListener("beforeunload", beforeUnloadHandler);
        };
      }, []);

    return (
        <>
            <PageLayout 
                contentSlot={[
                    <FormContainer 
                        question={"Before you see your results ..."}
                        description={"Submit your details & sign up to get our brochure direct to your inbox. Be the first to find out about new products & special offers."}
                        step={"5 / 5"}
                        formSlot={[
                            <FormProvider {...methods}>
                                <form style={{ width:'100%'}}>
                                {formSchema.map((field, index) => (
                                    <Field key={index} {...field} />
                                ))}
                                <div style={{ textAlign: 'center' }}>By providing your information, you acknowledge you have read, agree to, and consented to our <a target="_blank" href='https://www.machinerypartner.com/terms-of-use'>Terms Of Use</a> and <a target="_blank" href='https://www.machinerypartner.com/privacy-policy'>Privacy Policy</a>.</div>
                                </form>
                            </FormProvider>
                        ]}
                        buttonWrapper={[
                            <div className="mark-button-container">
                                <button className={`${alternate.button} ${alternate['is-text']}`} onClick={handleSkip}>
                                    Skip & see results
                                </button>
                                <button className={styles.button} type="submit" onClick={(e) => { 
                                    methods.handleSubmit((data) => handleSubmit(e, data))();
                                    }}>
                                    Submit & see results
                                </button>
                            </div>
                        ]}
                    />
                ]}
            />
        </>
    );
}