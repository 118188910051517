import React from "react";
import * as _Builtin from "./_Builtin";
import * as _utils from "./utils";
import _styles from "./ProductCard.module.css";

export function ProductCard({
  as: _Component = _Builtin.Block,

  detailsUrl = {
    href: "#",
  },

  productCardProps = {},
  brand = "Brand",
  type = "Type",
  productName = "Product Name",
  monthlyPrice = "$Price/mo",
  fullPrice = "$Price",
  productImage = "https://uploads-ssl.webflow.com/649e7d984e2a9b5b23e56bf2/649e7d9b4e2a9b5b23e56e07_image.svg",
  specLabel1 = "Spec Name",
  specValue1 = "Value",
  specLabel2 = "Spec Name",
  specValue2 = "Value",
  specLabel3 = "Spec Name",
  specValue3 = "Value",
  id,
}) {
  return (
    <_Component
      className={_utils.cx(_styles, "product-card")}
      id={_utils.cx(
        _styles,
        "w-node-_98d45e7d-98a4-d507-ce47-fbae430713eb-430713eb"
      )}
      tag="div"
      {...productCardProps}
    >
      <_Builtin.Link
        className={_utils.cx(_styles, "product-card_details")}
        button={false}
        options={detailsUrl}
      >
        <_Builtin.Block
          className={_utils.cx(_styles, "product-card_hover")}
          tag="div"
        >
          {"Details"}
        </_Builtin.Block>
      </_Builtin.Link>
      <_Builtin.Block
        className={_utils.cx(_styles, "product-card_header")}
        tag="div"
      >
        <_Builtin.Block
          className={_utils.cx(_styles, "product-card_title-wrapper")}
          id={_utils.cx(
            _styles,
            "w-node-_98d45e7d-98a4-d507-ce47-fbae430713f0-430713eb"
          )}
          tag="div"
        >
          <_Builtin.Block
            className={_utils.cx(_styles, "product-card_brand")}
            tag="div"
          >
            <_Builtin.Block
              className={_utils.cx(_styles, "brand-label")}
              tag="div"
            >
              {brand}
            </_Builtin.Block>
            <_Builtin.Block
              className={_utils.cx(_styles, "divider-vertical")}
              tag="div"
            />
            <_Builtin.Block
              className={_utils.cx(_styles, "category-label")}
              tag="div"
            >
              {type}
            </_Builtin.Block>
          </_Builtin.Block>
          <_Builtin.Block
            className={_utils.cx(_styles, "product-card_title")}
            tag="div"
          >
            {productName}
          </_Builtin.Block>
        </_Builtin.Block>
        <_Builtin.Block
          className={_utils.cx(_styles, "product-card_price-wrapper")}
          id={_utils.cx(
            _styles,
            "w-node-_98d45e7d-98a4-d507-ce47-fbae430713f9-430713eb"
          )}
          tag="div"
        >
          <_Builtin.Block
            className={_utils.cx(_styles, "product-card_monthly-price")}
            tag="div"
          >
            {monthlyPrice}
          </_Builtin.Block>
          <_Builtin.Block
            className={_utils.cx(_styles, "product-card_price")}
            tag="div"
          >
            {fullPrice}
          </_Builtin.Block>
        </_Builtin.Block>
      </_Builtin.Block>
      <_Builtin.Image
        className={_utils.cx(_styles, "product-card_image")}
        loading="lazy"
        width="auto"
        height="auto"
        alt="__wf_reserved_inherit"
        src={productImage}
      />
      <_Builtin.Block
        className={_utils.cx(_styles, "product-card_specs-wrapper")}
        tag="div"
      >
        <_Builtin.Block
          className={_utils.cx(_styles, "product-card_spec")}
          tag="div"
        >
          <_Builtin.Block
            className={_utils.cx(_styles, "product-card_spec-label")}
            tag="div"
          >
            {specLabel1}
          </_Builtin.Block>
          <_Builtin.Block
            className={_utils.cx(_styles, "product-card_spec-value")}
            tag="div"
          >
            {specValue1}
          </_Builtin.Block>
        </_Builtin.Block>
        <_Builtin.Block
          className={_utils.cx(_styles, "divider-vertical")}
          tag="div"
        />
        <_Builtin.Block
          className={_utils.cx(_styles, "product-card_spec")}
          tag="div"
        >
          <_Builtin.Block
            className={_utils.cx(_styles, "product-card_spec-label")}
            tag="div"
          >
            {specLabel2}
          </_Builtin.Block>
          <_Builtin.Block
            className={_utils.cx(_styles, "product-card_spec-value")}
            tag="div"
          >
            {specValue2}
          </_Builtin.Block>
        </_Builtin.Block>
        <_Builtin.Block
          className={_utils.cx(_styles, "divider-vertical")}
          tag="div"
        />
        <_Builtin.Block
          className={_utils.cx(_styles, "product-card_spec")}
          tag="div"
        >
          <_Builtin.Block
            className={_utils.cx(_styles, "product-card_spec-label")}
            tag="div"
          >
            {specLabel3}
          </_Builtin.Block>
          <_Builtin.Block
            className={_utils.cx(_styles, "product-card_spec-value")}
            tag="div"
          >
            {specValue3}
          </_Builtin.Block>
        </_Builtin.Block>
      </_Builtin.Block>
      <_Builtin.Block
        className={_utils.cx(_styles, "product-card_button")}
        tag="div"
      >
        {"Details"}
      </_Builtin.Block>
    </_Component>
  );
}
