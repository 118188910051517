import React from "react";
import * as _Builtin from "./_Builtin";
import * as _utils from "./utils";
import _styles from "./FilterCardDefault.module.css";

export function FilterCardDefault({
  as: _Component = _Builtin.Block,
  description = "Description",
  title = "Title",
  image = "https://uploads-ssl.webflow.com/649e7d984e2a9b5b23e56bf2/649e7d9b4e2a9b5b23e56e07_image.svg",
  showImage = true,
  id,
  filterCardProps = {},
  showDescription = true,
}) {
  return (
    <_Component
      className={_utils.cx(_styles, "filter-card")}
      tag="div"
      id={id}
      {...filterCardProps}
    >
      {showImage ? (
        <_Builtin.Image
          className={_utils.cx(_styles, "filter-card_image")}
          loading="lazy"
          width="auto"
          height="auto"
          alt="__wf_reserved_inherit"
          src={image}
        />
      ) : null}
      <_Builtin.Block
        className={_utils.cx(_styles, "filter-card_content")}
        tag="div"
      >
        <_Builtin.Block
          className={_utils.cx(_styles, "filter-card_title")}
          tag="div"
        >
          {title}
        </_Builtin.Block>
        {showDescription ? (
          <_Builtin.Block
            className={_utils.cx(_styles, "filter-card_description")}
            tag="div"
          >
            {description}
          </_Builtin.Block>
        ) : null}
      </_Builtin.Block>
    </_Component>
  );
}
