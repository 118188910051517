import { atom } from 'jotai';

export const resultsPageState = atom(false);
export const loadingState = atom(true);
export const excavatorAtom = atom(false);
export const skidAtom = atom(false);
export const airtableRecordIdAtom = atom(null);


// Skid atoms
export const miniSkidAtom = atom(false);
export const mediumSkidAtom = atom(false);
export const largeSkidAtom = atom(false);

export const standardSelectedAtom = atom(false);
export const highSelectedAtom = atom(false);

export const shortSelectedAtom = atom(false);
export const mediumSelectedAtom = atom(false);
export const longSelectedAtom = atom(false);

// Excavator atoms
export const miniExAtom = atom(false);
export const smallExAtom = atom(false);
export const mediumExAtom = atom(false);
export const largeExAtom = atom(false);

export const underExAtom = atom(false);
export const overExAtom = atom(false);

export const mediumMaterialAtom = atom(false);
export const longMaterialAtom = atom(false);
