import React from "react";
import * as _Builtin from "./_Builtin";
import * as _utils from "./utils";
import _styles from "./FormContainer.module.css";

export function FormContainer({
  as: _Component = _Builtin.Block,
  formSlot,
  buttonWrapper,
  step = "1 / 4",
  question = "Title",
  showDescription = true,
  description = "Description",
  showStepper = true,
}) {
  return (
    <_Component className={_utils.cx(_styles, "filter-modal")} tag="div">
      <_Builtin.Block
        className={_utils.cx(_styles, "filter-modal_content-wrapper")}
        tag="div"
      >
        <_Builtin.Block
          className={_utils.cx(_styles, "filter-modal_content")}
          tag="div"
        >
          {showStepper ? (
            <_Builtin.Block
              className={_utils.cx(_styles, "filter-modal_stepper")}
              tag="div"
            >
              <_Builtin.Block
                className={_utils.cx(
                  _styles,
                  "text-style-label",
                  "text-color-weaker"
                )}
                tag="div"
              >
                {"Step"}
              </_Builtin.Block>
              <_Builtin.Block
                className={_utils.cx(
                  _styles,
                  "text-style-label",
                  "text-color-weaker"
                )}
                tag="div"
              >
                {step}
              </_Builtin.Block>
            </_Builtin.Block>
          ) : null}
          <_Builtin.Heading
            className={_utils.cx(_styles, "heading-style-h5")}
            tag="h1"
          >
            {question}
          </_Builtin.Heading>
          {showDescription ? (
            <_Builtin.Block tag="div">{description}</_Builtin.Block>
          ) : null}
        </_Builtin.Block>
        <_Builtin.Block
          className={_utils.cx(_styles, "filter-modal_cards-wrapper")}
          tag="div"
        >
          {formSlot}
        </_Builtin.Block>
      </_Builtin.Block>
      <_Builtin.Block
        className={_utils.cx(_styles, "filter-modal_btn-wrapper")}
        tag="div"
      >
        {buttonWrapper}
      </_Builtin.Block>
    </_Component>
  );
}
